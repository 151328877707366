<template>
  <div
    class="HeaderTemplate"
    :style="{ 'background-image': `url(${require(`@/assets/images/${image}`)})` }"
  >
    <div class="fill-content">
      <TransitionSlide :side="transition">
        <slot />
      </TransitionSlide>
    </div>
  </div>
</template>

<script>
import TransitionSlide from '@/transitions/TransitionSlide';

export default {
  components: {
    TransitionSlide,
  },

  props: {
    image: {
      type: String,
      default: 'evaluation-header.jpg',
    },

    transition: {
      type: String,
      default: 'bottom',
    },
  },
};
</script>

<style lang="scss" scoped>
.HeaderTemplate{
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  color: #fff;
  padding: $spacing-xs;
  padding-top: $spacing-5xl;
  font-size: $font-size-l;
  font-weight: 500;
  overflow: hidden;

  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: #0008;
    backdrop-filter: blur(3px);
  }
}
</style>

<template>
  <form
    class="Login"
    @input="validation($event)"
  >
    <div class="form-body">
      <h1 class="title">
        {{ $t('LOGIN.TITLE') }}
      </h1>

      <p class="description">
        {{ $t('LOGIN.DESCRIPTION') }}
      </p>

      <BaseInput
        v-for="(field, i) in fields"
        :key="i"
        v-model="field.value"
        v-bind="field"
        theme="light"
      >
        <template #error>
          <ValidationError
            :errors="errors"
            :name="field.name"
          />
        </template>
      </BaseInput>
    </div>

    <div class="form-footer">
      <BaseButton
        class="recover"
        size="s"
        variation="tertiary"
        @click="$emit('toggle-view', 'PasswordRecover')"
      >
        {{ $t('RECOVER.BUTTON') }}
      </BaseButton>

      <BaseButton
        class="login-btn"
        size="l"
        variation="primary"
        @click="submit"
      >
        {{ $t('LOGIN.BUTTON') }}
      </BaseButton>

      <BaseButton
        class="register"
        size="s"
        variation="tertiary"
        @click="$emit('toggle-view', 'Register')"
      >
        {{ $t('LOGIN.REGISTER') }}
      </BaseButton>
    </div>
  </form>
</template>

<script>
import ValidationError from '@/components/common/ValidationError';
import ValidationMixin from '@/mixins/ValidationMixin';

export default {
  components: {
    ValidationError,
  },

  mixins: [ValidationMixin],

  data() {
    return {
      fields: [
        {
          type: 'text',
          name: 'username',
          icon: ['fas', 'envelope'],
          placeholder: this.$t('EMAIL.PLACEHOLDER'),
          error: this.$t('EMAIL.ERROR'),
          required: true,
          value: undefined,
          autocomplete: 'on',
          autofocus: true,
        },
        {
          type: 'password',
          name: 'password',
          icon: ['fas', 'key'],
          placeholder: this.$t('PASSWORD.PLACEHOLDER'),
          error: this.$t('PASSWORD.ERROR'),
          required: true,
          value: undefined,
          autocomplete: 'on',
        },
      ],
    };
  },

  mounted() {
    this.focusFirstInput();
  },

  methods: {
    focusFirstInput() {
      const firstInput = this.$el.querySelector('input#username');
      if (firstInput) firstInput.focus();
    },

    async submit() {
      try {
        const currentForm = document.forms[0];
        this.checkAllFields(currentForm);

        if (this.areErrors()) {
          throw new Error(this.$t('FORM.MESSAGE.ERROR.CHECKFIELDS'));
        }

        const formData = new FormData(currentForm);
        const data = this.fields.reduce((acc, field) => (Object.assign(acc, { [field.name]: formData.get(field.name) })), {});
        this.send(data);
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async send(data) {
      await this.$store.dispatch('authModule/login', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.Login{
  display: grid;
  align-items: center;
  justify-content: center;
  gap: $spacing-s;

  .form-body {
    display: grid;
    gap: $spacing-xs;

    .title{
      font-size: $font-size-2xl;
      letter-spacing: 1px;
      font-weight: 500;
      text-transform: uppercase;
      color: $color-neutral-darker;
    }

    .description{
      margin-bottom: $spacing-m;
      font-size: $font-size-s;
    }
  }

  .form-footer {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $spacing-m;
    gap: $spacing-s;

    .login-btn {
      text-transform: uppercase;
      font-weight: 500;
    }

    .recover {
      color: $color-neutral-darker;
    }
  }
}
</style>

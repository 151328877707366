/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';
import axios from 'axios';

const getDefaultState = () => ({
  loading: false,
  modules: [],
  // contents: [],
  // giraBoxes: [],
  // jobBoxes: [],ç
  // currentPhase: undefined,
});

const state = () => getDefaultState();

export const getters = {
  isLoading: state => state.loading,
};

export const mutations = {
  ...make.mutations(state),
};

export const actions = {
  async fetchModules({ commit }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get('/modules');
      if (error) throw new Error(data);

      console.log(data.modules);

      commit('SET_MODULES', data.modules);
    } catch (e) {
      // console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // async fetchPhase({ commit }, phaseKey) {
  //   try {
  //     commit('SET_LOADING', true);

  //     const { data, error } = await axios.get(`/module/${phaseKey}`);
  //     if (error) throw new Error(data);

  //     commit('SET_CURRENT_MODULE', ({ ...data, key: phaseKey }));
  //   } catch (e) {
  //     // console.log(e);
  //   } finally {
  //     commit('SET_LOADING', false);
  //   }
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "MenuTooltipFooter", attrs: { role: "navigation" } },
    [
      _c("MenuTooltipFooterButton", {
        attrs: {
          to: "/profile",
          icon: ["fas", "user"],
          title: _vm.$t("HEADER.PROFILE.TITLE"),
          subtitle: _vm.$t("HEADER.PROFILE.DESCRIPTION")
        }
      }),
      _c(
        "button",
        {
          on: {
            click: function($event) {
              return _vm.logout()
            }
          }
        },
        [
          _c("font-awesome-icon", { attrs: { icon: ["fas", "door-open"] } }),
          _c("div", [
            _c("p", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(_vm.$t("HEADER.LOGOUT.TITLE")) + " ")
            ]),
            _c("p", { staticClass: "subtitle" }, [
              _vm._v(" " + _vm._s(_vm.$t("HEADER.LOGOUT.DESCRIPTION")) + " ")
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
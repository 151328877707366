<template>
  <div
    class="BlockQuestion"
    :class="question.template"
  >
    <slot name="question" />

    <div
      v-show="statementLabel"
      class="label"
      v-html="statementLabel"
    />

    <component
      :is="questionType"
      :key="question.id"
      v-model="innerValue"
      class="question"
      v-bind="questionAttributes"
      @input="answerSelected(question.id, ($event && $event.target) ? $event.target.value : $event)"
      @upload-file="$emit('upload-file', {questionId: question.id, questionKey: question.key, file: $event})"
      v-on="$listeners"
    >
      <template #error>
        <slot />
      </template>
    </component>
  </div>
</template>

<script>
import RadioChoices from '@/components/common/RadioChoices.vue';
import MultipleChoices from '@/components/common/MultipleChoices.vue';
import RadioBoxes from '@/components/common/RadioBoxes.vue';
import MultipleBoxes from '@/components/common/MultipleBoxes.vue';
import RadioChoicesCustom from '@/components/common/RadioChoicesCustom.vue';
// import BaseSelect from '@/components/base/BaseSelect.vue';

import Statement from '@/components/statements/Statement.vue';

import ModelMixin from '@/mixins/ModelMixin';

const QUESTIONS_TYPES = {
  statement: 'Statement',
  input: 'BaseInput',
  text: 'BaseText',
  choice: 'RadioChoices',
  choices: 'MultipleChoices',
  box: 'RadioBoxes',
  boxes: 'MultipleBoxes',
  choiceCustom: 'RadioChoicesCustom',
  switcher: 'BaseSwitcher',
  select: 'BaseSelect',
  file: 'BaseFile',
};

export default {
  components: {
    RadioChoices,
    MultipleChoices,
    RadioBoxes,
    MultipleBoxes,
    RadioChoicesCustom,
    Statement,
    // BaseSelect,
  },

  mixins: [ModelMixin],

  props: {
    question: {
      type: Object,
      required: true,
    },

    errors: {
      type: Array,
      default: undefined,
    },

    temporary: {
      type: Boolean,
      default: false,
    },

    allowModifyAnswers: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    statementLabel() {
      return this.question.texts.question;
      // const i18n = this.question.attributes?.i18n;
      // return (i18n && this.$te(`QUESTION.${i18n}.LABEL`)) ? this.$t(`QUESTION.${i18n}.LABEL`) : this.$t(`QUESTION.${this.question.key}.LABEL`);
    },

    hasStatementLabel() {
      return true;
    },

    isStatement() {
      return this.question.type === 'statement';
    },

    questionType() {
      return (typeof QUESTIONS_TYPES[this.question.type] !== 'undefined') ?
        QUESTIONS_TYPES[this.question.type] :
        QUESTIONS_TYPES.input;
    },

    questionAttributes() {
      return Object.assign({}, this.question.attributes, {
        name: this.question.key,
        disabled: this.isDisabled,
        options: this.question.texts?.options,
      });
    },

    isDisabled() {
      const isAnswered = this.innerValue !== undefined;
      if (this.question.type === 'validation') return false;

      return (isAnswered && !this.temporary && !this.allowModifyAnswers);
    },
  },

  methods: {
    answerSelected(questionId, answer) {
      const questionKey = this.question.key;
      this.$emit('answer-selected', { questionId, questionKey, answer });
    },

    // uploadFile(questionId, file) {
    // this.$emit('upload-file', { questionId, file });
    // },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid-template.scss';

.BlockQuestion{
  padding: $spacing-2xs $spacing-s;

  .label{
    display: block;
    margin-bottom: $spacing-s;
    font-weight: 500;

    &::v-deep{
      b{
        font-weight: 600;
      }

      p{
        font-weight: 400;
        text-align: justify;
        margin-bottom: $spacing-s;
      }

      p:last-child{
        margin-bottom: $spacing-m;
      }
    }

  }

  .question.disabled{
    pointer-events: none;
    filter: grayscale(50%) opacity(.6);
  }

  @media print{
    page-break-inside: avoid;
  }
}
</style>

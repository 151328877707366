var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "MultipleBoxes" },
    [
      _c(
        "fieldset",
        {
          staticClass: "items",
          style: { gridTemplateColumns: _vm.gridColumns }
        },
        [
          _c("legend", [_vm._v(_vm._s(_vm.labelTag))]),
          _vm._l(_vm.choices, function(choice, i) {
            return _c("div", { key: i, staticClass: "content" }, [
              _c("div", { staticClass: "choice" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.innerValue,
                      expression: "innerValue"
                    }
                  ],
                  attrs: {
                    id: "" + _vm.labelTag + i,
                    name: _vm.labelTag,
                    type: "checkbox"
                  },
                  domProps: {
                    value: choice.value,
                    checked: Array.isArray(_vm.innerValue)
                      ? _vm._i(_vm.innerValue, choice.value) > -1
                      : _vm.innerValue
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.innerValue,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = choice.value,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.innerValue = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.innerValue = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.innerValue = $$c
                      }
                    }
                  }
                }),
                _c("label", { attrs: { for: "" + _vm.labelTag + i } }, [
                  _vm.getImage(i)
                    ? _c("div", {
                        staticClass: "image",
                        style: [
                          {
                            "background-image": "url(" + _vm.getImage(i) + ")"
                          },
                          { "background-size": _vm.getImagesTypesDefined(i) }
                        ]
                      })
                    : _vm._e(),
                  _c("span", [_vm._v(_vm._s(choice.text))])
                ])
              ])
            ])
          })
        ],
        2
      ),
      _vm._t("error")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "SimpleStatement" }, [
    _vm.image
      ? _c("div", {
          staticClass: "image",
          style: { "background-image": "url(" + _vm.image + ")" }
        })
      : _vm._e(),
    _vm.$attrs.options ? _c("div", { staticClass: "text" }) : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
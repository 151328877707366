<template>
  <a
    class="BaseLink"
    :class="[themeClass, `variation--${variation}`, `size--${size}`]"
    v-bind="$attrs"
  >
    <slot />
  </a>
</template>

<script>
const variationValues = ['primary', 'secondary', 'tertiary', 'quaternary'];
const sizeValues = ['xs', 's', 'm', 'l'];

export default {
  props: {
    variation: {
      type: String,
      default: 'primary',
      validator: variation => variationValues.includes(variation),
    },

    size: {
      type: String,
      default: 'm',
      validator: size => sizeValues.includes(size),
    },
  },

  methods: {
    click() {
      this.$el.classList.remove('animated');
      this.$el.classList.add('animated');

      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.BaseLink {
  box-shadow: inset 0 0 0 1px var(--button-border-color);
  border: 0;
  border-radius: 50px;
  color: var(--button-color);
  background: var(--button-background-color);
  padding: var(--button-padding);
  text-decoration: none;

  font-size: var(--button-font-size);
  font-weight: 500;
  text-transform: uppercase;

  position: relative;
  z-index: 1;

  display: grid;
  grid-auto-flow: column;
  gap: $spacing-xs;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  transition: all 0.3s ease;

  &::after,
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    height: 100%;
    top: 0;
    width: 0;
    left: -100%;
    transform: skew(50deg);
    transform-origin: top left;
    transition: all 0.4s ease;
  }


  &::before {
    background: var(--button-background-color-before-hover);
    transition-duration: 0.3s;
  }

  &::after {
    background: var(--button-background-color-after-hover);
    transition-duration: 0.6s;
  }

  &:hover {
    cursor: pointer;
    color: var(--button-color-hover);
    outline: none;

    &::after,
    &::before{
      width: 200%;
    }
  }

  &:focus{
    outline: 2px solid $color-primary;
    border: 2px solid $color-neutral-lightest;
    // outline-offset: 1px;
    // box-shadow: 0 0 2px 1px $color-neutral-lightest;
  }

  &.disabled{
    filter: grayscale(90%) opacity(50%);
    pointer-events: none;
    // text-decoration: line-through;
  }
}

.theme--light.variation{
  &--primary{
    --button-color: #fff;
    --button-background-color: #{$color-primary};
    --button-color-hover: #{$color-neutral-lightest};
    --button-background-color-before-hover: #{$color-primary-lighter};
    --button-background-color-after-hover: #{$color-primary-light};
    --button-border-color: #{$color-primary};
  }

  &--secondary{
    --button-color: #{$color-primary};
    --button-color-hover: #{$color-primary-dark};
    --button-background-color: #fff;
    --button-background-color-before-hover: #{$color-primary-lightest};
    --button-background-color-after-hover: #{$color-primary-lighter};
    --button-border-color: #{$color-primary};
  }

  &--tertiary{
    --button-color: #{$color-primary};
    --button-color-hover: #{$color-primary-darker};
    --button-background-color: #fff;
    --button-background-color-before-hover: #{$color-primary-lightest};
    --button-background-color-after-hover: transparent;
    --button-border-color: transparent;
  }

  &--quaternary{
    --button-color: #{$color-neutral-dark};
    --button-color-hover: #{$color-primary-light};
    --button-background-color: transparent;
    --button-background-color-before-hover: transparent;
    --button-background-color-after-hover: transparent;
    --button-border-color: transparent;
  }
}

.theme--dark.variation{
  &--primary{
    --button-color: #{$color-neutral-lightest};
    --button-background-color: #{$color-primary};
    --button-color-hover: #{$color-neutral-lightest};
    --button-background-color-before-hover: #{$color-primary-lighter};
    --button-background-color-after-hover: #{$color-primary-light};
    --button-border-color: #{$color-primary};
  }

  &--secondary{
    --button-color: #{$color-primary};
    --button-color-hover: #{$color-primary-dark};
    --button-background-color: #fff;
    --button-background-color-before-hover: #{$color-primary-lightest};
    --button-background-color-after-hover: #{$color-primary-lighter};
    --button-border-color: #{$color-primary};
  }

  &--tertiary{
    --button-color: #{$color-neutral-lightest};
    --button-background-color: transparent;
    --button-color-hover: #{$color-neutral-lightest};
    --button-background-color-hover: #{$color-neutral-light};
    --button-border-color: #{$color-neutral-lightest};
  }

  &--quaternary{
    --button-color: #{$color-neutral-dark};
    --button-color-hover: #{$color-primary-light};
    --button-background-color: transparent;
    --button-background-color-before-hover: transparent;
    --button-background-color-after-hover: transparent;
    --button-border-color: transparent;
  }
}


.size{
  &--xs{
    --button-padding: 0;
    --button-font-size: #{$font-size-xs};
  }

  &--s{
    --button-padding: #{$spacing-xs} #{$spacing-m};
    --button-font-size: #{$font-size-xs};
  }

  &--m{
    --button-padding: #{$spacing-xs} #{$spacing-xl};
    --button-font-size: #{$font-size-s};
  }

  &--l{
    --button-padding: #{$spacing-s} #{$spacing-l};
    --button-font-size:#{$font-size-m};
  }
}
</style>

<template>
  <div class="Questionnaire">
    <TransitionSlide>
      <FormBlocks
        v-if="blocks"
        theme="light"
        :code="code"
        :form-blocks="blocks"
        :submission-errors="submissionErrors"
        :answers="answers"
        @change-block="saveBlockAnswers"
        @save-answer="saveAnswer"
        @upload-file="uploadFile"
        @finish-form="finishForm"
      />
    </TransitionSlide>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

import TransitionSlide from '@/transitions/TransitionSlide.vue';
import FormBlocks from '@/components/blocks/FormBlocks.vue';

const STORE = 'questionnaireModule';

export default {
  components: {
    TransitionSlide,
    FormBlocks,
  },

  props: {
    code: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      initialized: false,
    };
  },

  computed: {
    blocks: get(`${STORE}/blocks`),

    submissionErrors: get(`${STORE}/errors`),

    answers: get(`${STORE}/answers`),

    autosave: get(`${STORE}/autosave`),
  },

  async created() {
    this.$store.dispatch(`${STORE}/initModule`);

    await this.$store.dispatch(`${STORE}/fetchQuestions`, this.code);
    await this.$store.dispatch(`${STORE}/fetchAnswers`, this.code);
  },

  methods: {
    async saveBlockAnswers() {
      if (this.autosave) {
        await this.$store.dispatch(`${STORE}/sendAnswers`);
      }
    },

    async saveAnswer(answer) {
      await this.$store.dispatch(`${STORE}/saveAnswer`, answer);
    },

    async finishForm() {
      await this.$store.dispatch(`${STORE}/sendAnswers`);

      if (!this.submissionErrors) {
        this.$emit('finish');
      }
    },

    async uploadFile({ questionId, questionKey, file }) {
      if (!questionId) return;

      await this.$store.dispatch(`${STORE}/sendFileAnswer`, { questionId, questionKey, file });
    },
  },
};
</script>

<style lang="scss" scoped>
.Questionnaire{
  display: grid;

  .FormBlocks{
    // width: 100%;
    // max-width: 1000px;
    // margin: 0 auto;

    ::v-deep .BlockPagination .pagination-content{
      padding: $spacing-l $spacing-2xl;
    }
  }
}
</style>

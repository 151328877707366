var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CooperationView" },
    [
      _c("HeaderTemplate", [
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$t("COOPERATION_HEADER")) }
        })
      ]),
      _c(
        "div",
        { staticClass: "content fill-content" },
        [
          _c(
            "div",
            { staticClass: "users" },
            _vm._l(_vm.visibleUsers, function(user, j) {
              return _c("UserCard", {
                key: j,
                attrs: { user: user },
                nativeOn: {
                  click: function($event) {
                    return _vm.openUserCard(user)
                  }
                }
              })
            }),
            1
          ),
          _c(
            "BaseButton",
            {
              staticClass: "cooperation-button",
              on: {
                click: function($event) {
                  _vm.modalVisible = true
                }
              }
            },
            [_vm._v(" Quiero cooperar ")]
          ),
          _vm.modalVisible
            ? _c(
                "BaseModal",
                {
                  attrs: { "header-visible": false, size: "s" },
                  on: { close: _vm.closeModal }
                },
                [
                  _c("CooperationModalContent", {
                    on: { close: _vm.closeModal }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="RadioBoxes">
    <fieldset
      class="items"
      :style="{gridTemplateColumns: gridColumns}"
    >
      <legend>{{ labelTag }}</legend>

      <div
        v-for="(choice, i) in choices"
        :key="i"
        class="content"
      >
        <div class="choice">
          <input
            :id="`${labelTag}${i}`"
            v-model="innerValue"
            :name="labelTag"
            type="radio"
            :value="choice.value"
          >

          <label :for="`${labelTag}${i}`">
            <div
              v-if="getImage(i)"
              class="image"
              :style="[{ 'background-image': 'url(' + getImage(i) + ')' }, { 'background-size': getImagesTypesDefined(i) }]"
            />

            <span>{{ choice.text }}</span>
          </label>
        </div>
      </div>
    </fieldset>

    <slot name="error" />
  </div>
</template>

<script>
import ModelMixin from '@/mixins/ModelMixin';
import i18nAttributeMixin from '@/mixins/i18nAttributeMixin';

const LETTER_SIZE = 12;
const MAX_WIDTH = 300;
const MIN_CHOICE_SIZE = 30;

export default {
  mixins: [ModelMixin, i18nAttributeMixin],

  computed: {
    gridColumns() {
      if (this.choices) {
        const choiceMaxWidth = this.getChoiceMaxWith();
        const autoClass = this.$attrs?.grid === 'fit' ? 'fit' : 'fill';
        let minSize = (choiceMaxWidth * LETTER_SIZE) + LETTER_SIZE * 2;
        minSize = (minSize > MAX_WIDTH) ? MAX_WIDTH : minSize;
        const size = (minSize > MIN_CHOICE_SIZE) ? minSize : MIN_CHOICE_SIZE;

        return `repeat(auto-${autoClass}, minmax(${size}px, 1fr))`;
      }
      return undefined;
    },

    choices() {
      return this.$attrs.choices.map(choice => ({ value: choice, text: this.getChoice(choice) ?? choice }));
    },

    labelTag() {
      return this.$attrs.name;
    },
  },

  methods: {
    getImage(i) {
      const images = this.$attrs?.images;
      if (!images) return undefined;

      // eslint-disable-next-line
      return require(`@/assets/images/${images[i]}`);
    },

    getImagesTypesDefined(i) {
      if (!this.$attrs?.imagesTypes || !this.$attrs?.images) return 'cover';

      return this.$attrs?.imagesTypes[i];
    },

    getChoiceMaxWith() {
      return this.choices.reduce((acc, choice) => ((choice.text.length > acc) ? choice.text.length : acc), 0);
    },

    getChoice(choice) {
      if (this.$attrs?.subtype && this.$attrs?.subtype === 'number') return undefined;

      return this.$attrs?.options[choice];
    },
  },
};
</script>


<style lang="scss" scoped>
.RadioBoxes {
  display: grid;
  gap: $spacing-2xs;

  .items{
    display: grid;
    gap: $spacing-xs;

    legend{
      display: none;
    }

    .content{
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 100%;
      gap: $spacing-2xs;

      .choice{
        label{
          height: 100%;
          padding: $spacing-xs;
          border: 1px solid transparent;
          border-radius: 5px;
          display: grid;
          align-content: center;
          text-align: center;
          gap: $spacing-xs;
          background-color: $color-neutral-lighter;
          box-shadow: $shadow-elevation-1;
          transition: border .3s ease,color .3s ease;
          position: relative;

          .image {
            height: 150px;
            margin-bottom: $spacing-s;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 5px;
          }

          &:hover{
            cursor: pointer;
            border-color: $color-primary;
            color:  $color-primary-dark;
          }
        }

        input {
          height: 0;
          width: 0;
          position: absolute;

          &:checked + label {
            background: $color-primary;
            color: #fff;
            font-weight: 500;
            animation: heartbeat 1.5s ease-in-out both;
          }

          &:focus + label{
            box-shadow: 0 0 2px 1px $color-primary;
          }
        }
      }
    }
  }
}
</style>

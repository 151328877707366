var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Questionnaire" },
    [
      _c(
        "TransitionSlide",
        [
          _vm.blocks
            ? _c("FormBlocks", {
                attrs: {
                  theme: "light",
                  code: _vm.code,
                  "form-blocks": _vm.blocks,
                  "submission-errors": _vm.submissionErrors,
                  answers: _vm.answers
                },
                on: {
                  "change-block": _vm.saveBlockAnswers,
                  "save-answer": _vm.saveAnswer,
                  "upload-file": _vm.uploadFile,
                  "finish-form": _vm.finishForm
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
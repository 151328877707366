<template>
  <TransitionSlide side="bottom">
    <div
      class="QuestionnaireCard"
      :class="{complete: isComplete, current: isCurrent, disabled: !isComplete && !isCurrent}"
    >
      <div class="content">
        <p class="title">
          {{ $t(`QUESTIONNAIRE.${code}`) }}
        </p>

        <p class="block">
          {{ $t(`EVALUATION.${block}`) }}
        </p>
      </div>

      <font-awesome-icon
        :icon="icon"
        class="icon fa-4x"
      />
    </div>
  </TransitionSlide>
</template>

<script>
import TransitionSlide from '@/transitions/TransitionSlide';

export default {
  components: {
    TransitionSlide,
  },

  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  computed: {
    code() {
      return this.content?.code;
    },

    block() {
      return this.content?.block;
    },

    icon() {
      const icon = this.content?.icon;
      if (!icon) return '';

      const [type, ...name] = icon.split('-');
      return [type, name.join('-')];
    },

    isComplete() {
      return this.content?.complete;
    },

    isCurrent() {
      return this.content?.isCurrent;
    },
  },
};
</script>

<style lang="scss" scoped>
.QuestionnaireCard{
  &.disabled{
    --card-border: none;
    --card-background: #fff;
    --title-color: #{$color-neutral-mid-dark};
    --subtitle-color: #{$color-neutral-mid-darker};
    --icon-color: #{adjust-color($color-neutral-mid-darker, $alpha: -.8)};
    pointer-events: none;
  }

  &.complete{
    --card-border: none;
    --card-background: #fff;
    --title-color: #{$color-primary-dark};
    --subtitle-color: #000;
    --icon-color: #{adjust-color($color-primary-lighter, $alpha: -.6)};
  }

  &.current{
    --card-border: 2px solid #{$color-secondary};
    --card-background: #fff;
    --title-color: #{$color-secondary-dark};
    --subtitle-color: #000;
    --icon-color:rgb(255 235 204);
    --icon-color: #{adjust-color($color-secondary-lighter, $alpha: -.6)};
  }

  display: grid;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: $shadow-elevation-1;
  background: var(--card-background);
  border: var(--card-border);
  position: relative;
  z-index: 1;
  transition: all .3s ease;

  .content{
    padding: $spacing-xs;
    .title{
      font-size: $font-size-s;
      text-transform: uppercase;
      font-weight: 500;
      color: var(--title-color);
      position: relative;
    }

    .block{
      font-size: $font-size-xs;
      color: var(--subtitle-color);
    }
  }

  .icon{
    position: absolute;
    font-size: 6rem;
    transform: rotate(-10deg);
    bottom: -1.25rem;
    right: .5rem;
    color:  var(--icon-color);
    z-index: -1rem;
  }

  &.complete:hover,
  &.current:hover{
    cursor: pointer;
  }
}
</style>

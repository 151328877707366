<template>
  <footer class="TheFooter">
    <div class="footer window-fit">
      <img
        class="image"
        :src="require(`@/assets/images/institucional.jpg`)"
        alt="Institucional"
      >

      <p class="develop-by">
        {{ $tc('FOOTER.DEVELOP_BY', null, [new Date().getFullYear()]) }}
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',

  computed: {
    giraSection() {
      return this?.$route?.matched[0]?.name ?? undefined;
    },
  },
};
</script>


<style lang="scss" scoped>
footer {
  background-color: $color-neutral-darkest;

  .footer {
    color: white;
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-m;
    align-items: center;
    padding: $spacing-s;
    font-size: $font-size-s;

    .image{
      width: 200px;
    }
  }
}
</style>

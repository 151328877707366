var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "MenuTooltipBody" }, [
    _c(
      "div",
      { staticClass: "image" },
      [
        _c("font-awesome-icon", {
          attrs: { size: "2x", icon: ["fas", "user"] }
        })
      ],
      1
    ),
    _c("div", { staticClass: "information" }, [
      _c(
        "p",
        {
          staticClass: "name",
          attrs: { "aria-label": "Nombre completo de la empresa" }
        },
        [_vm._v(" " + _vm._s(_vm.company) + " ")]
      ),
      _c(
        "p",
        { staticClass: "email", attrs: { "aria-label": "Email del usuario" } },
        [_vm._v(" " + _vm._s(_vm.email) + " ")]
      ),
      _c(
        "div",
        { staticClass: "points" },
        [
          _c("span", [_vm._v(" " + _vm._s(_vm.points) + " ")]),
          _c("font-awesome-icon", { attrs: { icon: ["fas", "coins"] } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div
    class="UserCard"
  >
    <img
      :src="image"
      alt="profile-image"
    >

    <div class="information">
      <div class="main-data">
        <p class="name">
          {{ name }}
        </p>

        <p class="email">
          {{ email }}
        </p>
      </div>

      <div class="secondary-data">
        <p class="position">
          <font-awesome-icon
            class="icon"
            :icon="['fas','user-tie']"
          />
          {{ position }}
        </p>

        <p class="company">
          <font-awesome-icon
            class="icon"
            :icon="['far','building']"
          />
          {{ company }}
        </p>

        <p class="linkedin">
          <font-awesome-icon
            class="icon"
            :icon="['fab','linkedin']"
          />
          <a :href="linkedin">{{ linkedin }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  computed: {
    image() {
      return this.user?.image;
    },

    name() {
      return this.user?.name;
    },

    position() {
      return this.user?.position;
    },

    email() {
      return this.user?.email;
    },

    company() {
      return this.user?.company;
    },

    linkedin() {
      return this.user?.linkedin;
    },

  },
};
</script>

<style lang="scss" scoped>
.UserCard{
  background: #fff;
  box-shadow: $shadow-elevation-1;
  display: grid;
  border-radius: $border-radius-m;

  img{
    margin: $spacing-s auto;
    object-fit: cover;
    height: 150px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 3px solid $color-neutral-light;
  }

  .information{
    .main-data{
      padding: $spacing-s;
      background: $color-primary-lightest;
      // margin-bottom: $spacing-s;
      text-align: center;

      .name{
        font-weight: 500;
        color: $color-primary-darker;
      }

      .email{
        font-size: $font-size-s;
      }
    }

    .secondary-data {
      background: #fff;
      padding: $spacing-s;
      display: grid;
      gap: $spacing-2xs;

      p{
        display: flex;
        gap: $spacing-s;
        align-items: baseline;
      }

      .position{
        font-size: $font-size-s;
        .icon{
          color: $color-primary-darkest;
        }
      }

      .company{
        font-size: $font-size-s;
        .icon{
          color: $color-secondary-darker;
        }
      }

      .linkedin{
        font-size: $font-size-s;
        .icon{
          color: #0077B7;
        }

        a{
          color: #0077B7;
          text-decoration: none;
        }
      }
    }


  }

}
</style>

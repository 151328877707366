var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "MenuTooltipFooterButton",
      attrs: { to: _vm.to, "aria-label": "Acceder a " + _vm.title }
    },
    [
      _c("font-awesome-icon", { attrs: { icon: _vm.icon } }),
      _c("div", [
        _c("p", { staticClass: "title" }, [
          _vm._v(" " + _vm._s(_vm.title) + " ")
        ]),
        _c("p", { staticClass: "subtitle" }, [
          _vm._v(" " + _vm._s(_vm.subtitle) + " ")
        ])
      ]),
      _vm.extra
        ? _c("span", { staticClass: "not-read" }, [
            _vm._v(" " + _vm._s(_vm.extra) + " ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BlockQuestion", class: _vm.question.template },
    [
      _vm._t("question"),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.statementLabel,
            expression: "statementLabel"
          }
        ],
        staticClass: "label",
        domProps: { innerHTML: _vm._s(_vm.statementLabel) }
      }),
      _c(
        _vm.questionType,
        _vm._g(
          _vm._b(
            {
              key: _vm.question.id,
              tag: "component",
              staticClass: "question",
              on: {
                input: function($event) {
                  _vm.answerSelected(
                    _vm.question.id,
                    $event && $event.target ? $event.target.value : $event
                  )
                },
                "upload-file": function($event) {
                  return _vm.$emit("upload-file", {
                    questionId: _vm.question.id,
                    questionKey: _vm.question.key,
                    file: $event
                  })
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "error",
                    fn: function() {
                      return [_vm._t("default")]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              ),
              model: {
                value: _vm.innerValue,
                callback: function($$v) {
                  _vm.innerValue = $$v
                },
                expression: "innerValue"
              }
            },
            "component",
            _vm.questionAttributes,
            false
          ),
          _vm.$listeners
        )
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
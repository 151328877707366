var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LoginView" },
    [
      _c("TransitionFade", [
        _c("div", { staticClass: "form-panel" }, [
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c("img", {
                staticClass: "logo",
                attrs: {
                  src: require("@/assets/images/logo.svg"),
                  alt: "icc logo"
                }
              }),
              _c(
                "TransitionSlide",
                { attrs: { side: "right" } },
                [
                  _c(_vm.currentView, {
                    tag: "component",
                    on: { "toggle-view": _vm.toggleView }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="QuestionnaireView">
    <Questionnaire
      v-if="code"
      :code="code"
      @finish="finishQuestionnaire"
    />
  </div>
</template>

<script>

import Questionnaire from '@/components/modules/Questionnaire.vue';

export default {
  components: {
    Questionnaire,
  },

  data() {
    return {
      code: undefined,
    };
  },

  mounted() {
    this.code = this.$route.params?.code;

    if (!this.code) {
      this.$router.push({ path: '/home' });
    }
  },

  methods: {
    finishQuestionnaire() {
      this.$router.push({ path: '/evaluation' });
    },
  },
};
</script>

<style lang="scss" scoped>
.QuestionnaireView{
  display: grid;
  position: relative;
  z-index: 2;

  .FormBlocks {
    &::v-deep .pagination-content{
      background-color: #fff;
      box-shadow: $shadow-elevation-2;
      border-radius: $border-radius-m;
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TransitionSlide",
    { attrs: { side: "bottom" } },
    [
      _c(
        _vm.componentAccordingFocusability,
        _vm._g(
          {
            tag: "component",
            staticClass: "Module",
            class: { available: _vm.content.available }
          },
          _vm.content.available ? { click: _vm.open } : {}
        ),
        [
          _c("div", { staticClass: "filter" }),
          _c("p", { staticClass: "title" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("MODULE." + _vm.content.code + ".TITLE")) +
                " "
            )
          ]),
          _c("p", { staticClass: "subtitle" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("MODULE." + _vm.content.code + ".SUBTITLE")) +
                " "
            )
          ]),
          _c("font-awesome-icon", {
            staticClass: "icon-left",
            attrs: { icon: ["fas", _vm.content.icons[0]] }
          }),
          _c("font-awesome-icon", {
            staticClass: "icon-right",
            attrs: { icon: ["fas", _vm.content.icons[1]] }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div
    class="MenuTooltipFooter"
    role="navigation"
  >
    <MenuTooltipFooterButton
      to="/profile"
      :icon="['fas', 'user']"
      :title="$t('HEADER.PROFILE.TITLE')"
      :subtitle="$t('HEADER.PROFILE.DESCRIPTION')"
    />

    <button @click="logout()">
      <font-awesome-icon :icon="['fas', 'door-open']" />
      <div>
        <p class="title">
          {{ $t('HEADER.LOGOUT.TITLE') }}
        </p>

        <p class="subtitle">
          {{ $t('HEADER.LOGOUT.DESCRIPTION') }}
        </p>
      </div>
    </button>
  </div>
</template>

<script>
import MenuTooltipFooterButton from '@/components/header/MenuTooltipFooterButton';

export default {
  components: {
    MenuTooltipFooterButton,
  },

  methods: {
    logout() {
      this.$store.dispatch('authModule/logout');
    },
  },
};
</script>

<style lang="scss" scoped>
.MenuTooltipFooter{
  background: $color-primary-lightest;

  button{
    display: flex;
    align-items: center;
    gap: $spacing-2xs;
    text-decoration: none;
    padding: $spacing-s $spacing-m;
    margin: 0;
    text-align: left;
    border: none;
    width: 100%;
    background: transparent;

    transition: all .5s ease;

    &:hover{
      background-color: $color-primary-lighter;
      color: $color-neutral-mid-darker;
      cursor: pointer;
    }

    .title{
      color: $color-primary-darkest;
      font-size: $font-size-s;
      margin-bottom: $spacing-2xs;
    }

    .subtitle{
      font-size: $font-size-xs;
      color: $color-neutral-darker;
    }

    svg{
      width: 25px;
      color: $color-primary;
      margin-right: $spacing-s;
    }
  }
}
</style>

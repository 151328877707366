<template>
  <div class="LoginView">
    <TransitionFade>
      <div class="form-panel">
        <div class="form-content">
          <img
            class="logo"
            :src="require(`@/assets/images/logo.svg`)"
            alt="icc logo"
          >
          <TransitionSlide side="right">
            <component
              :is="currentView"
              @toggle-view="toggleView"
            />
          </TransitionSlide>
        </div>
      </div>
    </TransitionFade>
  </div>
</template>

<script>
import TransitionFade from '@/transitions/TransitionFade';
import TransitionSlide from '@/transitions/TransitionSlide';
import Login from '@/components/login/Login';
import Register from '@/components/login/Register';
import PasswordRecover from '@/components/login/PasswordRecover';

const VIEWS = ['Login', 'Register', 'PasswordRecover'];

export default {
  components: {
    TransitionFade,
    TransitionSlide,
    Login,
    Register,
    PasswordRecover,
  },

  data() {
    return {
      currentView: undefined,
    };
  },

  created() {
    this.currentView = VIEWS[0];
  },

  mounted() {
    this.code = this.$route.params?.code;
    if (this.code) {
      this.currentView = 'PasswordRecover';
    }
  },

  methods: {
    async toggleView(nextView) {
      this.currentView = VIEWS.find(view => view === nextView);
    },
  },
};
</script>

<style lang="scss" scoped>
.LoginView{
  display: grid;
  place-items: center;
  padding: $spacing-2xs;
  background: url('~@/assets/images/login.jpg');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;

  .form-panel{
    width: 100%;
    max-width: 600px;
    min-height: 500px;
    display: grid;
    overflow: hidden;
    border-radius: $border-radius-m;

    aside{
      background: adjust-color($color: $color-primary-dark, $alpha: -.3);
      backdrop-filter: blur(3px);
      padding: $spacing-2xl $spacing-xl;
      color: #fff;
      text-align: justify;
    }

    .form-content{
      display: grid;
      justify-items: center;
      background: #fff;
      padding: $spacing-xl;

      .logo{
        width: 150px;
        margin-left: auto;
        margin-bottom: 1rem;
      }
    }
  }

  @media #{$breakpoint-md-max}{
    .form-panel .form .form-content {
      padding: $spacing-xl $spacing-m;
    }
  }
}
</style>

<template>
  <fieldset class="RadioChoicesCustom">
    <legend>{{ labelTag }}</legend>

    <div
      v-for="(choice, i) in choices"
      :key="i"
      class="choice-block"
    >
      <div class="choice">
        <input
          :id="`${labelTag}${i}`"
          v-model="valueOption"
          :name="labelTag"
          type="radio"
          :value="choice.value"
        >

        <label :for="`${labelTag}${i}`">
          <span>{{ choice.text }}</span>
        </label>

        <component
          :is="subquestionType(choice.subquestion)"
          v-if="choice.subquestion"
          v-model="choice.answer"
          class="subquestion"
          v-bind="subquestionAttributes(`sub-${labelTag}${i}`, choice.subquestion)"
          @click.native="valueOption = choice.value"
          @input="answerSubquestion(choice.value, ($event && $event.target) ? $event.target.value : $event)"
        />
      </div>
    </div>

    <slot name="error" />
  </fieldset>
</template>

<script>

const QUESTIONS_TYPES = {
  input: 'BaseInput',
  number: 'BaseInput',
  select: 'BaseSelect',
};

export default {
  props: {
    value: {
      type: null,
      default: undefined,
    },
  },

  data() {
    return {
      choices: undefined,
    };
  },

  computed: {
    innerValue: {
      get() {
        return this.value ?? { value: undefined };
      },

      set(value) {
        this.$emit('input', value);
      },
    },

    valueOption: {
      get() {
        return this.value?.value;
      },

      set(value) {
        this.clearOthersSubvalueAnswers();
        this.innerValue = this.hasSubquestion() ? { value, subvalue: undefined } : { value };
      },
    },

    labelTag() {
      return this.$attrs.name;
    },
  },

  created() {
    this.choices = this.formatChoices();
  },

  methods: {
    formatChoices() {
      return this.$attrs.choices.map(choice => ({
        value: choice.value,
        text: this.getChoice(choice.value) ?? choice.value,
        subquestion: choice.subchoice,
        answer: undefined,
      }));
    },

    getChoice(choice) {
      return this.$attrs?.options[choice];
    },

    subquestionType(subquestion) {
      return (typeof QUESTIONS_TYPES[subquestion?.type] !== 'undefined') ?
        QUESTIONS_TYPES[subquestion.type] :
        QUESTIONS_TYPES.input;
    },

    subquestionAttributes(name, subquestion) {
      const type = (subquestion?.type === 'number') ? 'number' : undefined;

      return Object.assign({}, subquestion, { type, name });
    },

    answerSubquestion(subquestionId, answer) {
      this.innerValue = { value: subquestionId, subvalue: answer };
    },

    clearOthersSubvalueAnswers(value) {
      this.choices = this.choices.map(choice => (choice.value !== value ? { ...choice, answer: undefined } : choice));
    },

    hasSubquestion(value) {
      return this.choices.findIndex(choice => choice.value === value && choice?.subquestion) >= 0;
    },
  },
};
</script>


<style lang="scss" scoped>
.RadioChoicesCustom {
  display: grid;
  gap: $spacing-2xs;

  legend{
    display: none;
  }

  .choice {
    display: flex;
    gap: $spacing-xs;

    label{
      padding: $spacing-2xs;
      display: flex;
      align-items: center;
      gap: $spacing-xs;

      label{display: none;}

      &:hover{
        cursor: pointer;
        border-color: $color-primary;
        color:  $color-primary;

        &:before {
          background-color: $color-primary;
          box-shadow: inset 0 0 0 3px #fff;
        }
      }

      &:before {
        content: "";
        aspect-ratio: 1;
        background: #fff;
        border: 1px solid $color-neutral-mid-dark;
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }

    input {
      height: 0;
      width: 0;
      position: absolute;
      opacity: 0;

      &:checked + label {
        color: $color-primary;
        font-weight: 500;

        &:before {
          border-color: $color-primary;
          background-color: $color-primary;
          box-shadow: inset 0 0 0 2px #fff;
        }
      }

      &:focus + label{
        box-shadow: 0 0 0px 1px $color-primary;
      }
    }
  }
}
</style>

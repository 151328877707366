<template>
  <div class="TheHeader">
    <header>
      <router-link
        to="/home"
        aria-label="Ir a la página principal"
      >
        <img
          class="logo"
          :src="require(`@/assets/images/logo.svg`)"
          alt="Logo Coca-Cola"
        >
      </router-link>

      <MenuTooltip :user="user" />
    </header>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import MenuTooltip from '@/components/header/MenuTooltip.vue';

const STORE = 'authModule';

export default {
  components: {
    MenuTooltip,
  },

  computed: {
    rootPathName() {
      return this?.$route?.matched[0]?.name;
    },

    user: get(`${STORE}/getUser`),
  },

  async created() {
    if (!this.user) {
      await this.$store.dispatch(`${STORE}/fetchUser`);
    }
  },
};
</script>

<style scoped lang="scss">
.TheHeader {
  background: #fff;
  box-shadow: $shadow-elevation-2;

  header{
    padding: 0 $spacing-xs;
    max-width: 1400px;
    margin: auto;
    display: flex;
    justify-items: space-between;

    a{
      display: grid;
      padding: $spacing-2xs;
    }
  }

  .logo{
    height: $theHeaderSpacing;
  }

  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
}
</style>

<template>
  <div
    class="BaseFile"
    :class="{uploaded: value}"
  >
    <label :for="labelTag">
      <p v-if="!value">Choose file to upload</p>

      <div v-if="value">
        <img
          v-if="isImage"
          class="preview-image"
          :src="value"
          alt="previsualización de imagen"
        >

        <font-awesome-icon
          v-else
          class="fa-4x file-uploaded"
          :icon="['fas','file-upload']"
        />

        <font-awesome-layers class="fa-lg icon">
          <font-awesome-icon icon="circle" />

          <font-awesome-icon
            class="check"
            icon="check-circle"
          />
        </font-awesome-layers>
      </div>

    </label>

    <input
      :id="labelTag"
      type="file"
      :name="labelTag"
      v-bind="inputAttributes"
      @change="loadImage($event)"
    >
  </div>
</template>
<script>

import ModelMixin from '@/mixins/ModelMixin';

export default {
  mixins: [ModelMixin],

  computed: {
    labelTag() {
      return this.$attrs.name;
    },

    inputAttributes() {
      return Object.assign({}, this.$attrs);
    },

    isImage() {
      return /image\//g.test(this.$attrs.accept);
    },
  },

  methods: {
    loadImage({ target }) {
      const [file] = target.files;
      if (!file) return;

      this.saveImage(file);
    },

    async saveImage(file) {
      this.innerValue = URL.createObjectURL(file);
      this.$emit('upload-file', file);
    },
  },
};
</script>

<style lang="scss" scoped>
  .BaseFile{
    display: grid;
    border: 1px dashed;
    place-content: center;
    gap: 0.5rem;
    padding: 1rem;
    justify-items: center;
    background: #ededed;

    &.uploaded{
      border: none;
      background: #fff;
    }

    label{
      font-weight: 500;
      position: relative;
      cursor: pointer;

      .file-uploaded{
        color: $color-neutral-dark;
      }

      .icon{
        position: absolute;
        right: -$spacing-2xs;
        bottom: -$spacing-2xs;
        color: #fff;
        animation: small-bounce .8s linear;
      }

      .check{
        color: $color-complete-darker;
      }
    }

    .preview-image{
      max-width: 250px;
      max-height: 200px;
    }
  }
</style>

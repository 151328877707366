var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "PaginationBullets", class: [_vm.themeClass] },
    _vm._l(_vm.bullets, function(bullet, i) {
      return _c("div", {
        key: bullet,
        staticClass: "bullet",
        class: [{ current: i === _vm.current }, { completed: i < _vm.current }]
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
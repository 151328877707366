<template>
  <div class="CloseEvaluationModalContent">
    <h1 class="question">
      {{ $t('CLOSE_EVALUATION.MODAL.TITLE') }}
    </h1>

    <p class="content">
      {{ $t('CLOSE_EVALUATION.MODAL.DESCRIPTION') }}
    </p>

    <div class="actions">
      <BaseButton
        class="finish-button"
        variation="secondary"
        @click="$emit('close')"
      >
        {{ $t('CLOSE_EVALUATION.MODAL.CANCEL_BUTTON') }}
      </BaseButton>

      <BaseButton
        class="finish-button"
        @click="$emit('close-evaluation')"
      >
        {{ $t('CLOSE_EVALUATION.MODAL.FINISH_BUTTON') }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.CloseEvaluationModalContent{
  padding: $spacing-l;

  .question{
    margin-bottom: $spacing-m;
    font-size: $font-size-l;
    font-weight: 500;
    text-align: center;
  }

  .content{
    margin-bottom: $spacing-m;
  }

  .actions{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: $spacing-m;
    align-items: center;
    justify-items: center;
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "CloseEvaluationModalContent" }, [
    _c("h1", { staticClass: "question" }, [
      _vm._v(" " + _vm._s(_vm.$t("CLOSE_EVALUATION.MODAL.TITLE")) + " ")
    ]),
    _c("p", { staticClass: "content" }, [
      _vm._v(" " + _vm._s(_vm.$t("CLOSE_EVALUATION.MODAL.DESCRIPTION")) + " ")
    ]),
    _c(
      "div",
      { staticClass: "actions" },
      [
        _c(
          "BaseButton",
          {
            staticClass: "finish-button",
            attrs: { variation: "secondary" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("CLOSE_EVALUATION.MODAL.CANCEL_BUTTON")) + " "
            )
          ]
        ),
        _c(
          "BaseButton",
          {
            staticClass: "finish-button",
            on: {
              click: function($event) {
                return _vm.$emit("close-evaluation")
              }
            }
          },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("CLOSE_EVALUATION.MODAL.FINISH_BUTTON")) + " "
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "EvaluationView" },
    [
      _c("HeaderTemplate", { attrs: { image: "evaluation-header.jpg" } }, [
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$t("EVALUATION_HEADER")) }
        })
      ]),
      _c(
        "div",
        { staticClass: "content fill-content" },
        [
          _vm._l(_vm.visibleQuestionnaires, function(questionnaire, j) {
            return _c("QuestionnaireCard", {
              key: j,
              attrs: {
                content: Object.assign({}, questionnaire, {
                  complete: questionnaire.complete && !_vm.evaluationClosed,
                  isCurrent: _vm.isCurrentQuestionnaire(questionnaire.code)
                })
              },
              nativeOn: {
                click: function($event) {
                  return _vm.openQuestionnaire(questionnaire)
                }
              }
            })
          }),
          _vm.evaluationCardVisible
            ? _c(_vm.finalCard, {
                tag: "component",
                attrs: { available: _vm.allQuestionnairesCompleted },
                nativeOn: {
                  click: function($event) {
                    return _vm.openModal.apply(null, arguments)
                  }
                }
              })
            : _vm._e(),
          _vm.modalVisible
            ? _c(
                "BaseModal",
                {
                  attrs: { "header-visible": false, size: "s" },
                  on: { close: _vm.closeModal }
                },
                [
                  _c("CloseEvaluationModalContent", {
                    on: {
                      close: _vm.closeModal,
                      "close-evaluation": _vm.closeEvaluationModule
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
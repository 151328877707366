/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';
import axios from 'axios';

const getDefaultState = () => ({
  loading: false,
  smarts: [],
  points: 0,
});

const state = () => getDefaultState();

export const getters = {
  isLoading: state => state.loading,

  getSmart: state => (factor, smartState) => state.smarts.find(smart => smart.keyFactor === factor && smart.state === smartState),
};

export const mutations = {
  ...make.mutations(state),

  CHOOSE_SMART(state, smartId) {
    const smart = state.smarts.find(smart => smart.id === smartId);
    smart.selected = !smart.selected;
  },
};

export const actions = {
  async fetchSmarts({ commit }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get('/strategies');
      if (error) throw new Error(data);

      commit('SET_SMARTS', data.smarts);
      commit('SET_POINTS', data.points);
    } catch (e) {
      // console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async selectSmart({ commit }, smartId) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post(`/select-smart/${smartId}`);
      if (error) throw new Error(data);


      commit('CHOOSE_SMART', smartId);
      commit('SET_POINTS', data.points);
    } catch (e) {
      // console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

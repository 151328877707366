var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "MenuTooltip",
      attrs: { "aria-label": "Menu desplegable del usuario" },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.toogleActive($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "profile" },
        [
          _c("font-awesome-icon", {
            attrs: { size: "lg", icon: ["fas", "user"] }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "content",
          attrs: { "aria-label": "Contenido del menu desplegable del usuario" }
        },
        [
          _c(
            "div",
            { staticClass: "content-sections" },
            [
              _vm.user
                ? _c("MenuTooltipBody", {
                    attrs: { user: _vm.user, "aria-label": "Datos del usuario" }
                  })
                : _vm._e(),
              _c("MenuTooltipFooter")
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue';
import VueRouter from 'vue-router';

import LoginView from '@/views/LoginView.vue';
import HomeView from '@/views/HomeView.vue';
import SelfEvaluationView from '@/views/EvaluationView.vue';
import StrategiesView from '@/views/StrategiesView.vue';
import QuestionnaireView from '@/views/QuestionnaireView.vue';
import EvaluationResultsView from '@/views/EvaluationResultsView.vue';
import CooperationView from '@/views/CooperationView.vue';

import store from '../store'; // your vuex store

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const requiredAuthenticated = (to, from, next) => {
  if (store.getters['authModule/isAuthenticated']) {
    next();
  } else {
    next({ path: 'login' });
  }
};

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    name: 'Login',
    path: '/login',
    component: LoginView,
    meta: { header: false },
  },
  {
    path: '/home',
    component: HomeView,
    beforeEnter: requiredAuthenticated,
    meta: { header: true },
  },
  {
    path: '/evaluation',
    component: SelfEvaluationView,
    beforeEnter: requiredAuthenticated,
    meta: { header: true },
  },
  {
    path: '/cooperation',
    component: CooperationView,
    beforeEnter: requiredAuthenticated,
    meta: { header: true },
  },
  {
    path: '/evaluation/results',
    component: EvaluationResultsView,
    beforeEnter: requiredAuthenticated,
    meta: { header: true },
  },
  {
    path: '/strategies',
    component: StrategiesView,
    beforeEnter: requiredAuthenticated,
    meta: { header: true },
  },
  {
    path: '/form/:code',
    props: route => ({ ...route.params, code: +route.params?.code }),
    component: QuestionnaireView,
    meta: { header: true },
  },
  {
    path: '/profile',
    component: () => import('@/views/ProfileView.vue'),
    beforeEnter: requiredAuthenticated,
    meta: { header: true },
  },
  {
    name: 'PasswordRecover',
    path: '/user/password/recover/:code*',
    props: route => ({ ...route.params, code: +route.params?.code }),
    component: () => import('@/views/PasswordRecoverCode.vue'),
    meta: { header: false },
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

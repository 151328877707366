<template>
  <div class="BarCard">
    <p class="title">
      {{ title }}
    </p>

    <BarChart
      :value="value"
      :color="color"
    />
  </div>
</template>

<script>
import BarChart from '@/components/results/BarChart';

export default {
  components: {
    BarChart,
  },

  props: {
    title: {
      type: String,
      default: undefined,
    },

    value: {
      type: Number,
      default: 0,
    },

    color: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.BarCard{
  .title{
    text-transform: uppercase;
    text-align: center;
    font-size: $font-size-xs;
    font-weight: 500;
    margin-bottom: $spacing-2xs;
  }
}
</style>

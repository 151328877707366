<template>
  <div class="StrategiesView">
    <HeaderTemplate image="strategies-header.jpg">
      <div
        class="strategies-header"
        v-html="$t('STRATEGIES_HEADER')"
      />
    </HeaderTemplate>

    <div class="content fill-content">
      <div class="points-content">
        <span
          v-if="points !== undefined"
          class="points"
        >
          <font-awesome-icon :icon="['fas', 'coins']" />
          <span>
            {{ $tc('POINTS.TEXT', points, [points]) }}
          </span>
        </span>
      </div>

      <div class="actions">
        <BaseInput
          v-model="searchText.value"
          class="search-input"
          v-bind="searchText"
          @input="search($event)"
        />

        <div class="filter">
          <span>{{ $t('SHOW.LABEL') }}:</span>
          <RadioBoxes
            v-bind="filter"
            @input="filterSmart"
          />
        </div>
      </div>

      <div class="smarts">
        <SmartProjectCard
          v-for="(smart, i) in visibleSmarts"
          :key="i"
          :content="smart"
          @click.native="openSmart(smart)"
        />
      </div>


      <BaseModal
        v-if="currentSmart"
        :header-visible="false"
        size="xxl"
        @close="closeSmart"
      >
        <SmartProject
          :smart="currentSmart"
          @choose="chooseSmart"
          @close="closeSmart"
        />
      </BaseModal>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import HeaderTemplate from '@/components/common/HeaderTemplate';
import RadioBoxes from '@/components/common/RadioBoxes';
import SmartProjectCard from '@/components/strategies/SmartProjectCard';
import SmartProject from '@/components/strategies/SmartProject';

const STORE = 'strategiesModule';

export default {
  components: {
    HeaderTemplate,
    RadioBoxes,
    SmartProjectCard,
    SmartProject,
  },

  data() {
    return {
      visibleSmarts: [],
      currentSmart: undefined,
      searchText: {
        type: 'text',
        name: 'search',
        icon: ['fas', 'search'],
        placeholder: this.$t('SEARCH.PLACEHOLDER'),
        value: undefined,
      },
      filter: {
        choices: [1, 2],
        options: { 1: this.$t('SEARCH.CHOICE.1'), 2: this.$t('SEARCH.CHOICE.2') },
        value: 1,
      },
    };
  },

  computed: {
    smarts: get(`${STORE}/smarts`),
    points: get(`${STORE}/points`),
  },

  async created() {
    await this.$store.dispatch(`${STORE}/fetchSmarts`);

    this.makeSmartsVisibles();
  },

  methods: {
    makeSmartsVisibles() {
      this.visibleSmarts = this.smarts;
    },

    openSmart(smart) {
      this.currentSmart = smart;
    },

    closeSmart() {
      document.querySelector('#app').classList.remove('hidden-scroll');
      this.currentSmart = undefined;
    },

    async chooseSmart(smartId) {
      await this.$store.dispatch(`${STORE}/selectSmart`, smartId);
      this.$store.set('authModule/user@points', this.points);
      this.closeSmart();
    },

    search(value) {
      this.searchText.value = value;
      if (!value) {
        this.visibleSmarts = this.smarts;
        return;
      }

      this.visibleSmarts = this.getSmartsFilteredByText(value);
    },

    getSmartsFilteredByText(value) {
      const slugifyValue = this.slugify(value);
      const regexp = new RegExp(`(${slugifyValue})`, 'gi');

      return this.smarts.filter(({ keyFactor, state }) => {
        const text = this.getSmartTitle(keyFactor, state);
        const slugifyText = this.slugify(text);
        return regexp.test(slugifyText);
      });
    },

    getSmartTitle(factor, state) {
      return this.$t(`SMART_PROJECT.KEY_FACTOR.${factor}.STATE.${state}.TITLE`);
    },

    slugify(text) {
      return text.replace(/\.|,/gi, '')
        .replace(/[&\\/\\#,+()$~%.'":*?<>{}]/g, '')
        .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
    },

    filterSmart($event) {
      this.filter.value = $event;

      const searchText = this.searchText.value;
      const visibleSmarts = (searchText) ? this.getSmartsFilteredByText(searchText) : this.smarts;

      const filterSelected = $event === 2;
      this.visibleSmarts = (filterSelected) ? visibleSmarts.filter(smart => smart.selected) : visibleSmarts;
    },
  },

};
</script>

<style lang="scss" scoped>
.StrategiesView{
  .strategies-header{
    font-size: $font-size-s;
    ::v-deep p:not(:last-child) {
      margin-bottom: $spacing-xs;
    }
  }
  .content{
    .points-content{
      display: flex;
      justify-content: center;
      padding: $spacing-s;

      .points{
        font-weight: 500;
        background-color: $color-secondary-dark;
        text-align: center;
        display: inline-block;
        padding: $spacing-xs $spacing-l;
        border-radius: 50px;
        color: #fff;
      }
    }

    .actions{
      display: flex;
      justify-content: space-between;
      display: flex;
      // grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
      flex-flow: wrap;
      gap: $spacing-s;
      padding: $spacing-m $spacing-s;

      .search-input{
        display: grid;
        grid-template-columns: 1fr auto;
        gap: $spacing-s;
        align-items: center;

      }

      .filter{
        display: grid;
        grid-template-columns: 1fr auto;
        gap: $spacing-s;
        align-items: center;

        span{
          text-align: right;
          font-weight: 500;
        }

        .RadioBoxes{
          width: 100%;
          min-width: 370px;
        }
      }
    }

    .smarts{
      padding: $spacing-l $spacing-s;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      grid-auto-rows: 200px;
      gap: $spacing-s;
    }
  }
}
</style>

<template>
  <div class="PieChart">
    <canvas
      :height="canvasSize"
      :width="canvasSize"
    />
  </div>
</template>

<script>

const STARTING_POINT = (-Math.PI / 2); // 4.72;

export default {
  props: {
    values: {
      type: Array,
      default: () => [],
    },

    colors: {
      type: Array,
      default: () => [],
    },

    size: {
      type: String,
      default: 'm',
    },
  },

  data() {
    return {
      counter: undefined,
      fill: undefined,
      data: undefined,
    };
  },

  computed: {
    canvasSize() {
      return this.size === 'm' ? 80 : 240;
    },

    circlesAttributes() {
      if (this.size === 'm') {
        return ({
          lineWidth: 4,
          radius: 20,
        });
      }

      return ({
        lineWidth: 8,
        radius: 60,
      });
    },
  },

  watch: {
    values: {
      deep: true,
      handler(newValue, oldValue) {
        const step = (newValue > oldValue) ? 1 : -1;
        this.fill = setInterval(() => {
          this.fillCounter(step);
        }, 20);
      },
    },
  },

  mounted() {
    this.counter = this.$el.querySelector('canvas').getContext('2d');

    this.data = this.values.map(value => ({ value, current: 0 }));
    this.fill = setInterval(() => {
      this.fillCounter(+1);
    }, 20);
  },

  methods: {
    fillCounter(increment) {
      const { width, height } = this.counter.canvas;
      const { lineWidth, radius } = this.circlesAttributes;
      const lineSpacing = (lineWidth * 2) - (lineWidth / 2);
      // Clear canvas
      this.counter.clearRect(0, 0, width, height);

      const data = this.data.map(({ value, current }, i) => {
        this.drawCircle(current, this.colors[i], radius + (lineSpacing * i));

        const isFinished = this.fillFinish(increment, current, value);
        return { value, current, isFinished };
      });

      if (data.every(({ isFinished }) => isFinished)) {
        clearTimeout(this.fill);
      } else {
        this.data = data.map(({ value, current, isFinished }) => {
          if (isFinished) return { value, current };

          return { value, current: current + increment };
        });
      }


      // const diffValue = ((this.currentValue / 100) * Math.PI * 2 * 10);
      // this.drawCircle(diffValue, this.colors[1], 25);

      // const finishValue = this.fillFinish(increment, this.currentValue, this.value);
      // const finishReference = this.fillFinish(increment, this.currentReference, this.reference);
      // if (finishValue && finishReference) {
      //   clearTimeout(this.fill);
      // } else {
      //   if (!finishValue) {
      //     this.currentValue += increment;
      //   }

      //   if (!finishReference) {
      //     this.currentReference += increment;
      //   }
      // }
    },

    drawCircle(value, color, radius) {
      const { width, height } = this.counter.canvas;
      const { lineWidth } = this.circlesAttributes;

      const centerWidth = width / 2;
      const centerHeight = height / 2;

      this.counter.beginPath();
      this.counter.strokeStyle = '#F0F2F4';
      // arc(x,y,radius,start,stop)
      this.counter.lineWidth = lineWidth - 2;
      this.counter.arc(centerWidth, centerHeight, radius + 0.5, STARTING_POINT, Math.PI * 2 + STARTING_POINT);
      this.counter.stroke();

      const RADIANS = ((Math.PI * 2) / 100);
      this.counter.beginPath();
      this.counter.strokeStyle = color;
      this.counter.lineWidth = lineWidth;
      this.counter.arc(centerWidth, centerHeight, radius, STARTING_POINT, STARTING_POINT + (value * RADIANS));
      this.counter.stroke();

      this.counter.beginPath();
      // const arcLength = 2 * Math.PI * radius * ((value / 10 + STARTING_POINT) / 360);
      // console.log(arcLength, 2 * Math.PI * radius);
      // const x = (2 * radius * value) / 100;
      // if (value > 25 && value <= 50) {
      //   x = -x;
      // } else if (value <= 75) {
      //   x = x;
      // } else {
      //   x = -x;
      // }
      // const v = value; // (radius * value) / 50;
      // const x = (v >= radius) ? -(radius - v) : v; // value;

      // const y = Math.sqrt(Math.pow(radius, 2) - Math.pow((x - centerWidth), 2)) + centerHeight;
      // this.counter.arc(x, y, 4, 0, 2 * Math.PI);
      // this.counter.stroke();
    },

    fillFinish(increment, value, total) {
      const finishAscending = (increment > 0) && (value >= total);
      const finishDescending = (increment < 0) && (value <= total);

      return (finishAscending || finishDescending);
    },
  },

};
</script>

<style lang="scss" scoped>
.PieChart{
  display: grid;
  place-content: center;
  position: relative;
  transition: all 0.3s ease;
}
</style>

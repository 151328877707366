var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { attrs: { id: "app" } },
    [
      _vm.headerVisible ? _c("TheHeader", { staticClass: "header" }) : _vm._e(),
      _c("router-view", { staticClass: "page-content" }),
      _c("TheFooter")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="BaseText">
    <span
      v-if="maxWords"
      class="remaining-words"
    >
      {{ remainingWords }}
    </span>

    <label
      class="invisible"
      :for="labelTag"
    >
      {{ labelTag }}
    </label>

    <textarea
      :id="labelTag"
      v-model="innerValue"
      v-bind="inputAttributes"
      :name="labelTag"
    />

    <slot name="error" />
  </div>
</template>

<script>

const MIN_ROWS = 5;

export default {
  props: {
    value: {
      type: null,
      default: undefined,
    },
  },

  computed: {
    innerValue: {
      get() {
        return this.value;
      },

      set(newValue) {
        const newWordsWritten = this.countWords(newValue);
        const noWordsLimit = !this.maxWords;
        const areFewerWords = newWordsWritten < this.maxWords;
        const removingWords = newWordsWritten <= this.wordsWritten;

        if (noWordsLimit || areFewerWords) {
          this.$emit('input', newValue);
        } else if (!areFewerWords && removingWords) {
          this.$emit('input', newValue.trim());
        } else {
          this.$emit('input', this.sliceExtraWords(newValue));
        }
      },
    },

    wordsWritten() {
      return this.countWords(this.innerValue);
    },

    rows() {
      return this.$attrs?.rows ?? MIN_ROWS;
    },

    placeholder() {
      const i18nText = this.$attrs?.placeholder;
      return i18nText || 'Escribe aquí_';
    },

    inputAttributes() {
      return Object.assign({}, this.$attrs, { label: undefined, rows: this.rows, placeholder: this.placeholder });
    },

    labelTag() {
      return this.$attrs.name;
    },

    maxWords() {
      return this.$attrs?.words;
    },

    remainingWords() {
      const words = this.maxWords - this.wordsWritten;
      return this.$tc('MESSAGE.REMAINING_WORDS', words, [words]);
    },
  },

  methods: {
    countWords(text) {
      const words = this.getWords(text);
      return words.length;
    },

    sliceExtraWords(text) {
      if (!this.maxWords) return text;

      const words = this.getWords(text);
      return words.filter((word, i) => i < this.maxWords).join(' ');
    },

    getWords(text) {
      return text?.match(/\S+/g) ?? [];
    },
  },
};
</script>


<style lang="scss" scoped>
.BaseText {
  display: grid;

  .remaining-words{
    margin-left: auto;
    font-size: $font-size-s;
    color: $color-neutral-dark;
    margin-bottom: $spacing-2xs;
  }

  textarea {
    resize: none;
    font-size: $font-size-m;
    padding: $spacing-m $spacing-l;
    border: none;
    border-radius: 10px;
    width: 100%;
    background-color: $color-neutral-lighter;
    box-shadow: $shadow-elevation-2;

    &:focus {
      outline: none;
      box-shadow: $shadow-elevation-1, 0 0 4px 0 $color-primary;
    }

    &:-moz-focusring {
      text-shadow: 0 0 0 transparent;
    }

    &.invalid {
      border: solid 1px #{$color-error};
    }
  }
}
</style>

<template>
  <div
    class="FileStatement"
    :class="{empty: src === undefined}"
  >
    <div
      v-if="$te(labeli18n)"
      class="label"
      v-html="$t(labeli18n)"
    />

    <div
      v-if="src"
      class="iframe-content"
    >
      <div class="iframe-content-header">
        <font-awesome-icon
          class="expand"
          :icon="['fas', 'expand']"
          @click="expand($event)"
        />

        <font-awesome-icon
          class="compress"
          :icon="['fas', 'compress']"
          @click="compress($event)"
        />
      </div>

      <iframe
        :src="src"
        width="100%"
      />
    </div>

    <div v-else>
      No file
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    src() {
      return `${process.env.VUE_APP_BASE_URL}/docs/${this.$attrs.src}`;
    },

    labeli18n() {
      return `STATEMENT.${this.$attrs.name}.LABEL`;
    },
  },

  methods: {
    expand({ target }) {
      const iframeContent = target?.closest('.iframe-content');
      if (iframeContent) {
        iframeContent.classList.remove('compress');
        iframeContent.classList.add('expanded');
      }
    },

    compress({ target }) {
      const iframeContent = target?.closest('.iframe-content');
      if (iframeContent) {
        iframeContent.classList.remove('expanded');
        iframeContent.classList.add('compress');
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.FileStatement{
  .label{
    margin-bottom: $spacing-xs;
  }

  &.empty{
    padding: $spacing-s;
    text-align: center;
  }

  &:not(.empty) {
    padding: $spacing-2xs;

    iframe{
      min-height: 500px;
      height: 100%;
    }
  }

  .iframe-content{
    box-shadow: $shadow-elevation-0;

    .iframe-content-header{
      background: $color-neutral-darkest;
      color: #fff;
      padding: $spacing-s;
      display: grid;
      justify-content: flex-end;
      grid-auto-columns: auto;
      grid-auto-flow: column;
      gap: $spacing-s;
      border-radius: $border-radius-m $border-radius-m 0 0;

      & > *:hover {
        cursor: pointer;
      }
    }

    iframe{
      border-radius: 0 0 $border-radius-m $border-radius-m;
    }

    &:not(.expanded) .iframe-content-header .compress,
    &.expanded .iframe-content-header .expand{
      display: none;
    }

    &.expanded{
      position: fixed;
      z-index: 20;
      left: $spacing-m;
      right: $spacing-m;
      background: #fff;
      top: $spacing-m;
      bottom: $spacing-m;
      border-radius: 3px;
      overflow: hidden;
      animation: small-bounce .5s;
      box-shadow: 0 0 0 2rem #0000008a;
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentError
    ? _c(
        "div",
        { staticClass: "ValidationError" },
        [
          _c("font-awesome-icon", {
            staticClass: "icon",
            attrs: { icon: ["fas", "exclamation"] }
          }),
          _vm._v(" " + _vm._s(_vm.currentError) + " ")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="BarChart">
    <div class="bar">
      <div
        class="progress"
        :style="`width:${currentValue}%;background-color:${color}`"
      />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },

    color: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      currentValue: 0,
      fill: undefined,
    };
  },

  watch: {
    value(newValue, oldValue) {
      const step = (newValue > oldValue) ? 1 : -1;
      this.fill = setInterval(() => {
        this.fillBar(step);
      }, 20);
    },
  },

  mounted() {
    this.fill = setInterval(() => {
      this.fillBar(+1);
    }, 20);
  },

  methods: {
    fillBar(increment) {
      if (this.fillFinish(increment)) {
        clearTimeout(this.fill);
      } else {
        this.currentValue += increment;
      }
    },

    fillFinish(increment) {
      const finishAscending = (increment > 0) && (this.currentValue >= this.value);
      const finishDescending = (increment < 0) && (this.currentValue <= this.value);

      return (finishAscending || finishDescending);
    },
  },

};
</script>

<style lang="scss" scoped>
.BarChart{
  transition: all 0.3s ease;
  min-width: 200px;

  .bar{
    background: #fff;
    height: 20px;
    width: 100%;
    border-radius: $border-radius-xl;
    overflow: hidden;
    box-shadow: $shadow-elevation-0;
    .progress{
      height: 100%;
      border-radius: $border-radius-xl;
    }
  }
}
</style>

<template>
  <form
    class="Register"
    @input="validation($event)"
  >
    <div class="form-body">
      <h1 class="title">
        {{ $t('REGISTER.TITLE') }}
      </h1>

      <p class="description">
        {{ $t('REGISTER.DESCRIPTION') }}
      </p>

      <BaseInput
        v-for="(field, i) in fields"
        :key="i"
        v-model="field.value"
        v-bind="field"
        theme="light"
      >
        <template #error>
          <ValidationError
            :errors="errors"
            :name="field.name"
          />
        </template>
      </BaseInput>

      <div
        id="policies"
        class="policies"
      >
        <BaseSwitcher
          v-model="privacyPolicies"
          tag="privacy-policies"
          @input="privacyPolicies=$event"
        />

        <p v-html="$t('POLICIES.OPEN_BUTTON')" />
      </div>
    </div>

    <div class="form-footer">
      <BaseButton
        class="register-btn"
        size="l"
        variation="primary"
        @click="submit"
      >
        {{ $t('LOGIN.REGISTER') }}
      </BaseButton>

      <BaseButton
        class="register"
        size="s"
        variation="tertiary"
        @click="$emit('toggle-view', 'Login')"
      >
        {{ $t('LOGIN.RETURN') }}
      </BaseButton>
    </div>

    <BaseModal
      v-if="modalVisible"
      :header-visible="false"
      size="m"
      @close="closeModal"
    >
      <PoliciesPanel
        @close="closeModal"
        @accept-policies="acceptPolicies"
      />
    </BaseModal>
  </form>
</template>

<script>
import ValidationError from '@/components/common/ValidationError';
import ValidationMixin from '@/mixins/ValidationMixin';
import PoliciesPanel from '@/components/common/PoliciesPanel';

export default {
  components: {
    ValidationError,
    PoliciesPanel,
  },

  mixins: [ValidationMixin],

  data() {
    return {
      fields: [
        {
          type: 'text',
          name: 'company',
          icon: ['fas', 'building'],
          placeholder: this.$t('COMPANY.PLACEHOLDER'),
          error: this.$t('FORM.ERROR.REQUIRED'),
          required: true,
          value: undefined,
          autocomplete: 'on',
        },
        {
          type: 'text',
          name: 'cif',
          icon: ['fas', 'id-badge'],
          placeholder: this.$t('CIF.PLACEHOLDER'),
          error: this.$t('FORM.ERROR.REQUIRED'),
          required: true,
          value: undefined,
        },
        {
          type: 'text',
          name: 'email',
          icon: ['fas', 'envelope'],
          placeholder: this.$t('EMAIL.PLACEHOLDER'),
          error: this.$t('EMAIL.ERROR'),
          required: true,
          value: undefined,
        },
        {
          type: 'password',
          name: 'password',
          icon: ['fas', 'key'],
          placeholder: this.$t('PASSWORD.PLACEHOLDER'),
          error: this.$t('FORM.ERROR.MINCHARACTERS', { number: 8 }),
          required: true,
          pattern: '.{8,}',
          value: undefined,
        },
        {
          type: 'password',
          name: 'reppassword',
          icon: ['fas', 'key'],
          placeholder: this.$t('REPPASSWORD.PLACEHOLDER'),
          error: this.$t('FORM.ERROR.MINCHARACTERS', { number: 8 }),
          required: true,
          pattern: '.{8,}',
          value: undefined,
        },
      ],
      privacyPolicies: false,
      modalVisible: false,
    };
  },

  watch: {
    privacyPolicies(newValue) {
      if (newValue) {
        const policiesField = this.$el.querySelector('#policies');
        this.removeValidationError(policiesField);
      }
    },
  },

  mounted() {
    this.focusFirstInput();

    const privacyPolicies = this.$el.querySelector('.policies strong');
    if (privacyPolicies) {
      privacyPolicies.addEventListener('click', this.openModal);
    }
  },

  methods: {
    focusFirstInput() {
      const firstInput = this.$el.querySelector('input#company');
      if (firstInput) firstInput.focus();
    },

    closeModal() {
      this.modalVisible = false;
    },

    acceptPolicies() {
      this.privacyPolicies = true;
      this.closeModal();
    },

    openModal() {
      this.privacyPolicies = false;
      this.modalVisible = true;
    },

    async submit() {
      try {
        const currentForm = document.forms[0];
        this.checkAllFields(currentForm);

        if (this.areErrors()) {
          throw new Error(this.$t('FORM.MESSAGE.ERROR.CHECKFIELDS'));
        }

        const formData = new FormData(currentForm);
        const data = this.fields.reduce((acc, field) => (Object.assign(acc, { [field.name]: formData.get(field.name) })), {});
        if (data.password !== data.reppassword) {
          const reppasswordField = currentForm.querySelector('[name=reppassword]');
          this.forceErrorElement(reppasswordField, this.$t('FORM.ERROR.REPEATPASSWORD'));

          throw new Error(this.$t('FORM.MESSAGE.ERROR.PASSWORDSMATCH'));
        }

        if (!this.privacyPolicies) {
          const policiesField = currentForm.querySelector('#policies');
          this.forceErrorElement(policiesField, this.$t('FORM.ERROR.REPEATPASSWORD'));

          throw new Error(this.$t('FORM.MESSAGE.ERROR.PRIVACY_POLICY'));
        }

        await this.send(data);
        this.$emit('toggle-view', 'Login');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async send(data) {
      await this.$store.dispatch('authModule/register', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.Register{
  display: grid;
  align-items: center;
  justify-content: center;
  gap: $spacing-s;

  .form-body {
    display: grid;
    gap: $spacing-xs;

    .title{
      font-size: $font-size-2xl;
      letter-spacing: 1px;
      font-weight: 500;
      text-transform: uppercase;
      color: $color-neutral-darker;
    }

    .description{
      margin-bottom: $spacing-m;
      font-size: $font-size-s;
    }

    .policies{
      padding: $spacing-xs;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin: auto;
      margin-top: $spacing-s;

      ::v-deep strong{
        font-weight: 500;
        cursor: pointer;
        color: $color-primary-dark;
      }
    }
  }

  .form-footer {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $spacing-m;
    gap: $spacing-s;

    .register-btn {
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}
</style>

<template>
  <button
    aria-label="Menu desplegable del usuario"
    class="MenuTooltip"
    @click.stop="toogleActive($event)"
  >
    <div class="profile">
      <font-awesome-icon
        size="lg"
        :icon="['fas', 'user']"
      />
    </div>

    <div
      class="content"
      aria-label="Contenido del menu desplegable del usuario"
    >
      <div class="content-sections">
        <MenuTooltipBody
          v-if="user"
          :user="user"
          aria-label="Datos del usuario"
        />

        <MenuTooltipFooter />
      </div>
    </div>
  </button>
</template>

<script>
import MenuTooltipBody from '@/components/header/MenuTooltipBody';
import MenuTooltipFooter from '@/components/header/MenuTooltipFooter';


export default {
  components: {
    MenuTooltipBody,
    MenuTooltipFooter,
  },

  props: {
    user: {
      type: Object,
      default: undefined,
    },
  },

  async created() {
    window.addEventListener('resize', this.calculeContentPosition);
  },

  updated() {
    this.calculeContentPosition();
  },

  mounted() {
    const headerHeight = document.querySelector('.TheHeader')?.offsetHeight;
    if (headerHeight) {
      const content = this.$el.querySelector('.content');
      content.style.top = `${headerHeight}px`;
    }

    this.calculeContentPosition();
  },

  methods: {
    calculeContentPosition() {
      const content = this.$el.querySelector('.content');
      if (content) {
        const header = document.querySelector('.TheHeader');
        const headerWidth = header?.offsetWidth ?? 0;
        const headerHeight = header?.offsetHeight ?? 0;
        const isWider = headerWidth - 1440;
        const offset = 9;
        const right = ((isWider > offset) ? (isWider / 2) : offset);

        const maxHeight = window.innerHeight - headerHeight - (offset * 2);
        content.style.right = `${right}px`;
        content.style.maxHeight = `${maxHeight}px`;
      }
    },

    toogleActive({ target }) {
      const isContent = target.classList.contains('content');
      const isFooterLink = (target.closest('.MenuTooltipFooter a') !== null);
      const isContentElement = (target.closest('.content') !== null);
      const isActive = this.$el.classList.contains('active');

      if (isFooterLink || isContent || (!isContentElement && isActive)) {
        this.$el.classList.remove('active');
      } else {
        this.$el.classList.add('active');
      }
    },
  },
};
</script>

<style lang="scss">
.MenuTooltip {
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  gap: $spacing-2xs;
  $arrowSize: 8px;
  padding: 0;
  border: none;
  text-align: left;

  &:hover{
    cursor: pointer;
  }

  .profile {
    --local-image-size: 80px;
    height: 100%;
    width: var(--local-image-size);
    background-color: $color-primary-dark;
    color: #fff;
    display: grid;
    place-content: center;

    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: calc(100% - #{$arrowSize});
      left: calc(50% - #{$arrowSize});
      border: $arrowSize solid transparent;
      z-index: 2;
      transition: all .6s ease;
    }
  }

  &.active{
    .profile::after{
      border-bottom-color: #fff;
    }
  }

  .content {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    right: $spacing-xs;
    left: $spacing-xs;
    margin-left: auto;
    margin-top: $arrowSize;
    border-radius: $border-radius-m;
    overflow: hidden;
    max-width: 450px;
    color: $color-neutral-dark;

    transition: visibility .6s ease, opacity .6s ease, right 0s linear;

    &::before {
      content: "";
      position: fixed;
      top: calc(#{$theHeaderSpacing} + #{$spacing-xs});
      left: 0;
      right: 0;
      bottom: 0;
      background: hsla(0, 0%, 0%, 0.5);
      z-index: -1;
    }

    .content-sections{
      flex: 1;
      overflow-y: auto;
      box-shadow: $shadow-elevation-2;
      background: #fff;
    }

    cursor: initial;
  }

  &.active .content{
    display: flex;
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }

  // @media #{$breakpoint-sm-max} {
  //   .profile {
  //     --local-image-size: 40px;
  //   }
  // }
}
</style>

/* eslint-disable no-shadow */
const DEFAULT_LOCALE = 'es';

const getUserLocale = () => {
  const locale = localStorage.getItem('locale');
  return locale || DEFAULT_LOCALE;
};

const getDefaultState = () => ({
  loading: false,
  locale: getUserLocale(),
  locales: { en: 'castellano', pt: 'portugues' },
});

const state = () => getDefaultState();

export const getters = {
  getLocale: state => state.locale,

  getLocales: state => () => state.locales,

  getLocaleByLanguage: state => (language) => {
    if (!language || !state.locales) return undefined;

    const localeEntries = Object.entries(state.locales);
    const current = localeEntries.find(([, text]) => text === language);
    return current ? current[1] : undefined;
  },

  getLanguage: state => (locale) => {
    if (!locale || !state.locales) return undefined;

    return state.locales[locale];
  },

  isValidlocale: state => (locale) => {
    if (!locale || !state.locales) return undefined;

    return locale in state.locales;
  },
};

const mutations = {
  SET_LOCALE(state, locale) {
    localStorage.setItem('locale', locale);
    state.locale = locale;

    try {
      window.Vue.$i18n.locale = locale;
    } catch (e) {
      console.log(e);
    }
  },
};

export const actions = {
  changeLocale({ getters, commit }, locale) {
    if (getters.isValidlocale(locale)) {
      commit('SET_LOCALE', locale);
      // console.log(window.Vue);
      // window.Vue.$i18n.locale = locale;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

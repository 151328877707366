var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "QuestionnaireView" },
    [
      _vm.code
        ? _c("Questionnaire", {
            attrs: { code: _vm.code },
            on: { finish: _vm.finishQuestionnaire }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div
    class="BaseSelect"
    :name="$attrs.name"
  >
    <div class="select-content">
      <select
        v-model="innerValue"
        :name="`select${$attrs.name}`"
        class="form-input"
        :class="{ 'has-selected-value': innerValue }"
        @input="onChange($event)"
      >
        <option
          class="default-option"
          value=""
          disabled
        >
          {{ $t('SELECT.PLACEHOLDER') }}
        </option>

        <option
          v-for="(choice, i) in choices"
          :key="i"
          :value="choice.value"
        >
          {{ choice.text }}
        </option>
      </select>

      <span
        class="dropdown-button"
        @click="$refs.select.focus()"
      >
        <font-awesome-icon
          class="arrow"
          :icon="['fas', 'chevron-right']"
        />
      </span>
    </div>

    <slot name="error" />
  </div>
</template>

<script>
// import ModelMixin from '@/mixins/ModelMixin';
import i18nAttributeMixin from '@/mixins/i18nAttributeMixin';

export default {
  mixins: [i18nAttributeMixin],

  inheritAttrs: false,

  props: {
    value: {
      type: null,
      default: '',
    },
  },

  computed: {
    innerValue: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.$emit('input', newValue);
      },
    },

    choices() {
      return this.$attrs.choices.map(choice => ({ value: choice, text: this.getChoice(choice) }));
    },
  },

  methods: {
    getChoice(choice) {
      if (this.$attrs?.subtype && this.$attrs?.subtype === 'number') return undefined;

      return this.$attrs?.options[choice];
      // const key = `QUESTION.${this.answerTag}.CHOICE.${choice}`;
      // if (this.$attrs?.subtype && this.$attrs?.subtype === 'number') {
      //   return this.$te(key) ? this.$t(key) : choice.toString();
      // }

      // return this.$t(key);
    },

    onChange(event) {
      this.innerValue = event.target.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.BaseSelect {
  max-width: 500px;
  .select-content{
    position: relative;
    display: grid;
  }

  select {
    width: 100%;
    flex: 1;
    padding: $spacing-xs $spacing-m;
    padding-right: $spacing-2xl;

    background-color: #fff;
    color: $color-neutral-darkest;
    border: solid 1px $color-neutral-mid-dark;
    border-radius: 50px;
    // font-size: $font-size-s;
    appearance: none;
    cursor: pointer;

    transition: border 0.5s ease, background-color 0.5s ease;

    option {
      color: $color-neutral-darker;
      font-size: $font-size-m;

      &.default-option{
        background: $color-neutral-light;
        font-weight: 300;
      }
    }

    &:focus {
      border-color: $color-primary;
      outline: none;
    }

    &:-moz-focusring {
      text-shadow: 0 0 0 transparent;
    }

    &.invalid {
      border: solid 1px red;
    }
  }

  .dropdown-button {
    display: flex;
    background-color: $color-primary;
    border-radius: 0 50px 50px 0;
    pointer-events: none;
    align-items: center;
    padding: $spacing-xs;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: grid;
    justify-content: center;
    width: 50px;

    .arrow {
      transition: all .3s ease;
      color: #fff;
    }
  }

  select:focus + .dropdown-button .arrow {
    transform: rotate(90deg);
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "HomeView" }, [
    _c("div", { staticClass: "banner" }),
    _c(
      "div",
      { staticClass: "content fill-content" },
      [
        _c("TransitionSlide", { attrs: { side: "right" } }, [
          _c("div", { staticClass: "panel-description" }, [
            _c("div", {
              staticClass: "description",
              domProps: { innerHTML: _vm._s(_vm.$t("HOME.DESCRIPTION")) }
            })
          ])
        ]),
        _vm.modules.length
          ? _c(
              "div",
              { staticClass: "modules" },
              _vm._l(_vm.modulesAttributes, function(module, i) {
                return _c("Module", {
                  key: module.code,
                  attrs: {
                    id: module.code,
                    content: module,
                    autofocus: i === 0
                  },
                  on: {
                    open: function($event) {
                      return _vm.redirectToModuleContent(module.path)
                    }
                  }
                })
              }),
              1
            )
          : _c(
              "div",
              { staticClass: "loading-modules" },
              _vm._l(2, function(i) {
                return _c("div", { key: i, staticClass: "loading-module" })
              }),
              0
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="CooperationModalContent">
    <font-awesome-icon
      class="icon"
      :icon="['fas','info-circle']"
    />

    <div
      class="content"
      v-html="$t('COOPERATION_MODAL.CONTENT')"
    />
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.CooperationModalContent{
  background: #fff;
  background: $color-primary-lightest;
  display: flex;

  .icon{
    margin: $spacing-m $spacing-m;
    font-size: $font-size-4xl;
    margin-bottom: $spacing-m;
    color: $color-primary-dark;
    color: $color-primary-dark;
    align-self: center;
  }

  .content{
    background: #fff;
    padding: $spacing-m;
    ::v-deep {
      strong{
        font-weight: 500;
      }

      p:not(:last-child){
        margin-bottom: $spacing-xs;
      }
    }
  }

}
</style>

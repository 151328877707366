var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "PoliciesPanel" },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v(" " + _vm._s(_vm.$t("POLICY.TITLE")) + " ")
      ]),
      _c("div", {
        staticClass: "content",
        domProps: { innerHTML: _vm._s(_vm.$t("POLICY.CONTENT")) }
      }),
      _c(
        "BaseButton",
        {
          attrs: { size: "s" },
          on: {
            click: function($event) {
              return _vm.$emit("accept-policies")
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("BUTTON.ACCEPT")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <router-link
    class="MenuTooltipFooterButton"
    :to="to"
    :aria-label="`Acceder a ${title}`"
  >
    <font-awesome-icon :icon="icon" />

    <div>
      <p class="title">
        {{ title }}
      </p>

      <p class="subtitle">
        {{ subtitle }}
      </p>
    </div>

    <span
      v-if="extra"
      class="not-read"
    >
      {{ extra }}
    </span>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: true,
    },

    icon: {
      type: Array,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      required: true,
    },

    extra: {
      type: Number,
      default: undefined,
    },
  },

};
</script>

<style lang="scss" scoped>
.MenuTooltipFooterButton{
  display: flex;
  align-items: center;
  gap: $spacing-2xs;
  text-decoration: none;
  padding: $spacing-s $spacing-m;
  margin: 0;

  transition: all .5s ease;

  &.disabled{
    filter: opacity(.8) grayscale(1);
    pointer-events: none;
  }

  &:hover{
    background-color: $color-primary-lighter;
    color: $color-neutral-mid-darker;
    cursor: pointer;
  }

  .title{
    color: $color-primary-darkest;
    font-size: $font-size-s;
    margin-bottom: $spacing-2xs;
  }

  .subtitle{
    font-size: $font-size-xs;
    color: $color-neutral-darker;
  }

  svg{
    width: 25px;
    color: $color-primary;
    margin-right: $spacing-s;
  }
}
</style>

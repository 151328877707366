var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BarCard" },
    [
      _c("p", { staticClass: "title" }, [
        _vm._v(" " + _vm._s(_vm.title) + " ")
      ]),
      _c("BarChart", { attrs: { value: _vm.value, color: _vm.color } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="SmartProjectView">
    <h2 class="smart-title">
      {{ $t(`SMART_PROJECT.KEY_FACTOR.${factor}.STATE.${state}.TITLE`) }}
    </h2>

    <div class="content">
      <div class="initial-final">
        <div class="initial">
          <p>
            <strong>{{ $t('SMART_PROJECT.INITIAL.TITLE') }}</strong>
          </p>

          {{ $t(`SMART_PROJECT.KEY_FACTOR.${factor}.STATE.${state}.INITIAL.TITLE`) }}
        </div>

        <div class="final">
          <p>
            <strong>{{ $t('SMART_PROJECT.FINAL.TITLE') }}</strong>
          </p>

          {{ $t(`SMART_PROJECT.KEY_FACTOR.${factor}.STATE.${state}.FINAL.TITLE`) }}
        </div>
      </div>

      <div class="milestones">
        <div
          v-for="milestone in 3"
          :key="milestone"
          class="milestone"
        >
          <div class="title">
            <p>
              <strong>{{ `Hito ${milestone}` }}</strong>
            </p>

            {{ $t(`SMART_PROJECT.KEY_FACTOR.${factor}.STATE.${state}.MILESTONE.${milestone}.TITLE`) }}
          </div>

          <div
            v-for="task in 3"
            :key="task"
            class="task"
          >
            <p>
              <strong>{{ `Tarea ${task}` }}</strong>
            </p>

            {{ $t(`SMART_PROJECT.KEY_FACTOR.${factor}.STATE.${state}.MILESTONE.${milestone}.TASK.${task}`) }}
          </div>
        </div>
      </div>


      <BaseButton
        class="select-button"
        v-bind="{disabled}"
        @click="choose"
      >
        <div>
          {{ selected ? $t('DESELECT.BUTTON') : $t('SELECT.BUTTON') }}

          <span class="points">
            {{ `( ${selected ? '+' : '-'} ${$tc('POINTS.LABEL', points, { points })} )` }}
          </span>
        </div>
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  props: {
    smart: {
      type: Object,
      required: true,
    },
  },

  computed: {
    userPoints: get('authModule/user@points'),

    factor() {
      return this.smart?.keyFactor;
    },

    state() {
      return this.smart?.state;
    },

    selected() {
      return this.smart?.selected;
    },

    points() {
      return this.smart?.points;
    },

    disabled() {
      return this.userPoints < this.points;
    },
  },

  methods: {
    choose() {
      this.$emit('choose', this.smart.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.SmartProjectView{
  padding: $spacing-m;

  .smart-title{
    text-align: center;
    font-size: $font-size-2xl;
    text-transform: uppercase;
    // color: $color-primary-darkest;
    color: $color-neutral-dark;
    padding: $spacing-m;
    border-bottom: 2px solid $color-neutral-mid;
  }

  .content{
    display: grid;
    align-content: flex-start;
    padding: $spacing-xl $spacing-m;
    gap: $spacing-m;

    strong{
      font-weight: 500;
      text-transform: uppercase;
    }

    .milestones{
      display: grid;
      gap: $spacing-m;
      grid-template-columns: repeat(3, 1fr);

      .milestone{
        display: grid;
        grid-template-rows: repeat(4, 1fr);
        // gap: $spacing-s;
        box-shadow: $shadow-elevation-1;
        overflow: hidden;
        border-radius: $border-radius-m;
        font-size: $font-size-s;

        & > *{
          padding: $spacing-s;
          text-align: center;
        }

        .title{
          background: $color-primary;
          color: #fff;
          // font-size: $font-size-s;
        }

        .task{
          // background: adjust-color($color-secondary-lightest, $lightness: 5%);
          background: #fff;
          // font-size: $font-size-s;

          strong{
            color: $color-secondary-darker;
          }

          &:not(:last-child){
            border-bottom: 1px dashed $color-neutral-mid;
          }
        }

      }

      @media #{$breakpoint-md-max}{
        grid-template-columns: 1fr;
      }
    }

    .initial-final{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: $spacing-m;


      .initial,
      .final{
        padding: $spacing-s;
        background: #fff;
        border-radius: $border-radius-m;
        font-size: $font-size-s;
        border: 1px solid transparent;
        box-shadow: $shadow-elevation-1;

        strong{
          color: $color-neutral-darker;
        }
      }

      .initial{
        border-color: $color-primary-light;
        background: adjust-color($color-primary-lightest, $lightness: 3%);

        strong{
          color: $color-primary;
        }
      }

      .final{
        border-color: $color-secondary-light;
        background: adjust-color($color-secondary-lightest, $lightness: 5%);

        strong{
          color: $color-secondary-dark;
        }
      }
    }

    .select-button{
      margin: auto;

      .points{
        font-size: $font-size-xs;
        text-transform: lowercase;
      }
    }
  }
}
</style>

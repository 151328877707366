<template>
  <form
    class="PasswordRecover"
    @input="validation($event)"
  >
    <div class="form-body">
      <h1 class="title">
        <BaseButton
          variation="quaternary"
          size="xs"
          aria-label="volver al login"
          @click="$emit('toggle-view', 'Login')"
        >
          <font-awesome-icon
            class="arrow"
            :icon="['fas', 'chevron-left']"
          />
        </BaseButton>

        {{ $t('RECOVER.TITLE') }}
      </h1>

      <BaseInput
        v-model="field.value"
        v-bind="field"
      >
        <template #error>
          <ValidationError
            :errors="errors"
            :name="field.name"
          />
        </template>
      </BaseInput>
    </div>

    <div class="form-footer">
      <BaseButton
        variation="primary"
        size="l"
        class="recover-btn"
        @click="submit"
      >
        {{ $t('SEND.BUTTON') }}
      </BaseButton>
    </div>
  </form>
</template>

<script>
import ValidationError from '@/components/common/ValidationError';
import ValidationMixin from '@/mixins/ValidationMixin';

export default {
  components: {
    ValidationError,
  },

  mixins: [ValidationMixin],

  data() {
    return {
      field: {
        type: 'text',
        name: 'email',
        placeholder: this.$t('EMAIL_RECOVER.PLACEHOLDER'),
        error: this.$t('EMAIL.ERROR'),
        required: true,
        value: undefined,
        autofocus: true,
      },
    };
  },

  mounted() {
    this.focusFirstInput();
  },

  methods: {
    focusFirstInput() {
      const firstInput = this.$el.querySelector('input#email');
      if (firstInput) firstInput.focus();
    },

    async submit() {
      try {
        const currentForm = document.forms[0];
        this.checkAllFields(currentForm);

        if (this.areErrors()) {
          throw new Error(this.$t('FORM.MESSAGE.ERROR.CHECKFIELDS'));
        }

        const formData = new FormData(currentForm);
        const data = { [this.field.name]: formData.get(this.field.name) };
        await this.send(data);

        this.$emit('toggle-view', 'Login');
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    async send(data) {
      await this.$store.dispatch('authModule/passwordRecover', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.PasswordRecover {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: $spacing-s;

  .form-body {
    margin-top: $spacing-l;
    display: grid;
    gap: $spacing-s;

    .title{
      font-size: $font-size-2xl;
      letter-spacing: 1px;
      font-weight: 500;
      text-transform: uppercase;
      color: $color-neutral-darker;

      display: grid;
      grid-auto-flow: column;
      align-items: center;
      gap: $spacing-xs;
    }
  }

  .form-footer {
    margin-top: $spacing-m;
    display: grid;
    place-items: center;
  }

  @media #{$breakpoint-md-max}{
    .form-body .title{
      font-size: $font-size-xl;
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseFile", class: { uploaded: _vm.value } },
    [
      _c("label", { attrs: { for: _vm.labelTag } }, [
        !_vm.value ? _c("p", [_vm._v("Choose file to upload")]) : _vm._e(),
        _vm.value
          ? _c(
              "div",
              [
                _vm.isImage
                  ? _c("img", {
                      staticClass: "preview-image",
                      attrs: {
                        src: _vm.value,
                        alt: "previsualización de imagen"
                      }
                    })
                  : _c("font-awesome-icon", {
                      staticClass: "fa-4x file-uploaded",
                      attrs: { icon: ["fas", "file-upload"] }
                    }),
                _c(
                  "font-awesome-layers",
                  { staticClass: "fa-lg icon" },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "circle" } }),
                    _c("font-awesome-icon", {
                      staticClass: "check",
                      attrs: { icon: "check-circle" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "input",
        _vm._b(
          {
            attrs: { id: _vm.labelTag, type: "file", name: _vm.labelTag },
            on: {
              change: function($event) {
                return _vm.loadImage($event)
              }
            }
          },
          "input",
          _vm.inputAttributes,
          false
        )
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="BaseInput">
    <div
      class="input"
      :class="`theme--${theme}`"
    >
      <div
        v-if="icon"
        class="icon"
      >
        <font-awesome-icon :icon="icon" />
      </div>

      <label
        :for="labelTag"
        class="invisible"
      >
        {{ labelTag }}
      </label>

      <input
        :id="labelTag"
        v-model="innerValue"
        :name="labelTag"
        :class="[{'right': units}, {'with-icon': icon}]"
        v-bind="inputAttributes"
      >

      <span
        v-if="units"
        class="units"
      >{{ units }}</span>
    </div>

    <slot name="error" />
  </div>
</template>

<script>

export default {
  inheritAttrs: false,

  props: {
    value: {
      type: null,
      default: undefined,
    },

    theme: {
      type: String,
      default: 'default',
      validator: theme => ['default', 'light'].includes(theme),
    },
  },

  computed: {
    innerValue: {
      get() {
        return this.value;
      },

      set(newValue) {
        let value = newValue;
        if (this.$attrs.type === 'number') {
          if (newValue === '') {
            value = undefined;
          } else {
            value = parseFloat(newValue, 10);
          }
        }

        this.$emit('input', value);
      },
    },

    labelTag() {
      return this.$attrs.name;
    },

    icon() {
      return this.$attrs?.icon;
    },

    units() {
      return this.$attrs?.units;
    },

    inputAttributes() {
      return Object.assign({}, this.$attrs, {
        label: undefined, type: this.type, icon: undefined,
      });
    },

    type() {
      return (!this.$attrs.type || this.$attrs.disabled) ? 'text' : this.$attrs.type;
    },
  },
};
</script>


<style lang="scss" scoped>
.BaseInput {
  display: flex;
  flex-direction: column;

  .input {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: $spacing-2xs;

    &.theme{
      &--default{
        --border-radius: 50px;
        --box-shadow: #{$shadow-elevation-1};
        --border-bottom: none;
        --background-color: #{$color-neutral-lighter};
        --left: #{$spacing-l};
        --padding-left: 3.5rem;
        --focus-shadow: #{$shadow-elevation-1}, 0 0 4px 0 #{$color-primary};

        input.invalid {
          border: solid 1px #{$color-error};
        }
      }

      &--light{
        --border-radius: 0;
        --box-shadow: none;
        --border-bottom: 1px solid #{$color-neutral-mid-dark};
        --background-color: transparent;
        --left: #{$spacing-s};
        --padding-left: #{$spacing-2xl};
        --focus-shadow: 0 1px 0px 0 #{$color-primary};

        input.invalid {
          border-bottom: solid 1px #{$color-error};
        }
      }
    }

    .icon{
      z-index: 3;
      color: $color-neutral-dark!important;
      position: absolute;
      top: 0;
      left: var(--left);
      bottom: 0;
      display: grid;
      place-content: center;

      & + input{
        padding-left: var(--padding-left);
      }
    }


    input {
      font-size: $font-size-m;
      border: none;
      padding: $spacing-s $spacing-m;
      border-bottom: var(--border-bottom);
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow);
      background-color: var(--background-color);
      max-width: 500px;
      width: 100%;

      &.with-icon{
        padding-left: $spacing-2xl;
      }

      &.right{
        text-align: right;
      }

      &:focus {
        outline: none;
        box-shadow: var(--focus-shadow);
      }

      &:-moz-focusring {
        text-shadow: 0 0 0 transparent;
      }

      &.invalid {
        border: solid 1px #{$color-error};
      }
    }
  }


  .units{
    font-weight: 500;
  }
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';

import authModule from './authModule';
import homeModule from './homeModule';
import questionnaireModule from './questionnaireModule';
import formModule from './formModule';
import strategiesModule from './strategiesModule';
import localeModule from './localeModule';
import resultsModule from './resultsModule';
import cooperationModule from './cooperationModule';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [pathify.plugin],

  modules: {
    authModule,
    homeModule,
    questionnaireModule,
    formModule,
    strategiesModule,
    localeModule,
    resultsModule,
    cooperationModule,
  },
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "FormBlocks" },
    [
      _vm.currentBlock
        ? _c(
            "HeaderTemplate",
            {
              attrs: {
                image: "login.jpg",
                transition: _vm.currentHeaderTransitionSide
              }
            },
            [
              _c("div", { key: _vm.currentBlock.id, staticClass: "header" }, [
                _c("p", { staticClass: "title" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("QUESTIONNAIRE." + _vm.code)) + " "
                  )
                ]),
                _c(
                  "p",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "subtitle",
                        attrs: {
                          to: "/evaluation",
                          "aria-label": "Ir a la página de evaluación"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "EVALUATION." + _vm.code.replace(/Q\d+/g, "")
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _vm.currentBlock.title
                      ? _c("span", [
                          _vm._v(
                            " " + _vm._s("/ " + _vm.currentBlock.title) + " "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ])
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "fill-content" },
        [
          _vm.questions
            ? _c(
                "BlockPagination",
                {
                  attrs: {
                    blocks: _vm.blocks || [],
                    "block-id": _vm.currentBlockId,
                    theme: _vm.theme
                  },
                  on: {
                    "change-block": _vm.changeBlock,
                    "submit-block": _vm.submitBlock
                  }
                },
                [
                  _c(
                    "TransitionSlide",
                    { attrs: { side: _vm.currentTransitionSide } },
                    [
                      _vm.formVisible
                        ? _c(
                            "form",
                            {
                              key: _vm.currentBlock.id,
                              on: {
                                input: function($event) {
                                  return _vm.validation($event)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "questions",
                                  style:
                                    "grid-template-areas: " + _vm.blockTemplate
                                },
                                _vm._l(_vm.questions, function(question, i) {
                                  return _c(
                                    "BlockQuestion",
                                    _vm._g(
                                      {
                                        key: i,
                                        attrs: {
                                          question: question,
                                          value: _vm.getAnswer(
                                            question.id,
                                            question.key
                                          ),
                                          errors: _vm.errors,
                                          "allow-modify-answers": true
                                        },
                                        on: {
                                          "answer-selected": _vm.answerSelected,
                                          "force-redirect": _vm.submitBlock
                                        }
                                      },
                                      _vm.$listeners
                                    ),
                                    [
                                      _c("ValidationError", {
                                        attrs: {
                                          errors: _vm.errors,
                                          name: question.key
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  !_vm.formVisible
                    ? _c("div", { staticClass: "loading-content" })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./BlockQuestion.vue?vue&type=template&id=7e3b59ba&scoped=true&"
import script from "./BlockQuestion.vue?vue&type=script&lang=js&"
export * from "./BlockQuestion.vue?vue&type=script&lang=js&"
import style0 from "./BlockQuestion.vue?vue&type=style&index=0&id=7e3b59ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e3b59ba",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Guillermo\\Proyectos\\icc_front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7e3b59ba')) {
      api.createRecord('7e3b59ba', component.options)
    } else {
      api.reload('7e3b59ba', component.options)
    }
    module.hot.accept("./BlockQuestion.vue?vue&type=template&id=7e3b59ba&scoped=true&", function () {
      api.rerender('7e3b59ba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/blocks/BlockQuestion.vue"
export default component.exports
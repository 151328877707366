var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "Login",
      on: {
        input: function($event) {
          return _vm.validation($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "form-body" },
        [
          _c("h1", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("LOGIN.TITLE")) + " ")
          ]),
          _c("p", { staticClass: "description" }, [
            _vm._v(" " + _vm._s(_vm.$t("LOGIN.DESCRIPTION")) + " ")
          ]),
          _vm._l(_vm.fields, function(field, i) {
            return _c(
              "BaseInput",
              _vm._b(
                {
                  key: i,
                  attrs: { theme: "light" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "error",
                        fn: function() {
                          return [
                            _c("ValidationError", {
                              attrs: { errors: _vm.errors, name: field.name }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: field.value,
                    callback: function($$v) {
                      _vm.$set(field, "value", $$v)
                    },
                    expression: "field.value"
                  }
                },
                "BaseInput",
                field,
                false
              )
            )
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "form-footer" },
        [
          _c(
            "BaseButton",
            {
              staticClass: "recover",
              attrs: { size: "s", variation: "tertiary" },
              on: {
                click: function($event) {
                  return _vm.$emit("toggle-view", "PasswordRecover")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("RECOVER.BUTTON")) + " ")]
          ),
          _c(
            "BaseButton",
            {
              staticClass: "login-btn",
              attrs: { size: "l", variation: "primary" },
              on: { click: _vm.submit }
            },
            [_vm._v(" " + _vm._s(_vm.$t("LOGIN.BUTTON")) + " ")]
          ),
          _c(
            "BaseButton",
            {
              staticClass: "register",
              attrs: { size: "s", variation: "tertiary" },
              on: {
                click: function($event) {
                  return _vm.$emit("toggle-view", "Register")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("LOGIN.REGISTER")) + " ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "StrategiesView" },
    [
      _c("HeaderTemplate", { attrs: { image: "strategies-header.jpg" } }, [
        _c("div", {
          staticClass: "strategies-header",
          domProps: { innerHTML: _vm._s(_vm.$t("STRATEGIES_HEADER")) }
        })
      ]),
      _c(
        "div",
        { staticClass: "content fill-content" },
        [
          _c("div", { staticClass: "points-content" }, [
            _vm.points !== undefined
              ? _c(
                  "span",
                  { staticClass: "points" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "coins"] }
                    }),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$tc("POINTS.TEXT", _vm.points, [_vm.points])
                          ) +
                          " "
                      )
                    ])
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c(
                "BaseInput",
                _vm._b(
                  {
                    staticClass: "search-input",
                    on: {
                      input: function($event) {
                        return _vm.search($event)
                      }
                    },
                    model: {
                      value: _vm.searchText.value,
                      callback: function($$v) {
                        _vm.$set(_vm.searchText, "value", $$v)
                      },
                      expression: "searchText.value"
                    }
                  },
                  "BaseInput",
                  _vm.searchText,
                  false
                )
              ),
              _c(
                "div",
                { staticClass: "filter" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("SHOW.LABEL")) + ":")]),
                  _c(
                    "RadioBoxes",
                    _vm._b(
                      { on: { input: _vm.filterSmart } },
                      "RadioBoxes",
                      _vm.filter,
                      false
                    )
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "smarts" },
            _vm._l(_vm.visibleSmarts, function(smart, i) {
              return _c("SmartProjectCard", {
                key: i,
                attrs: { content: smart },
                nativeOn: {
                  click: function($event) {
                    return _vm.openSmart(smart)
                  }
                }
              })
            }),
            1
          ),
          _vm.currentSmart
            ? _c(
                "BaseModal",
                {
                  attrs: { "header-visible": false, size: "xxl" },
                  on: { close: _vm.closeSmart }
                },
                [
                  _c("SmartProject", {
                    attrs: { smart: _vm.currentSmart },
                    on: { choose: _vm.chooseSmart, close: _vm.closeSmart }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
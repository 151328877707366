<template>
  <div class="FormBlocks">
    <HeaderTemplate
      v-if="currentBlock"
      image="login.jpg"
      :transition="currentHeaderTransitionSide"
    >
      <div
        :key="currentBlock.id"
        class="header"
      >
        <p class="title">
          {{ $t(`QUESTIONNAIRE.${code}`) }}
        </p>

        <p>
          <router-link
            class="subtitle"
            to="/evaluation"
            aria-label="Ir a la página de evaluación"
          >
            {{ $t(`EVALUATION.${code.replace(/Q\d+/g,'')}`) }}
          </router-link>

          <span v-if="currentBlock.title">
            {{ `/ ${currentBlock.title}` }}
          </span>
        </p>
      </div>
    </HeaderTemplate>

    <div class="fill-content">
      <BlockPagination
        v-if="questions"
        :blocks="blocks || []"
        :block-id="currentBlockId"
        :theme="theme"
        @change-block="changeBlock"
        @submit-block="submitBlock"
      >
        <TransitionSlide :side="currentTransitionSide">
          <form
            v-if="formVisible"
            :key="currentBlock.id"
            @input="validation($event)"
          >
            <div
              class="questions"
              :style="`grid-template-areas: ${blockTemplate}`"
            >
              <BlockQuestion
                v-for="(question, i) in questions"
                :key="i"
                :question="question"
                :value="getAnswer(question.id, question.key)"
                :errors="errors"
                :allow-modify-answers="true"
                @answer-selected="answerSelected"
                @force-redirect="submitBlock"
                v-on="$listeners"
              >
                <ValidationError
                  :errors="errors"
                  :name="question.key"
                />
              </BlockQuestion>
            </div>
          </form>
        </TransitionSlide>

        <div
          v-if="!formVisible"
          class="loading-content"
        />
      </BlockPagination>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

import HeaderTemplate from '@/components/common/HeaderTemplate';
import TransitionSlide from '@/transitions/TransitionSlide.vue';
import BlockQuestion from '@/components/blocks/BlockQuestion.vue';
import BlockPagination from '@/components/blocks/BlockPagination.vue';
import ValidationError from '@/components/common/ValidationError.vue';
import ValidationMixin from '@/mixins/ValidationMixin';

const store = 'formModule';

export default {
  components: {
    TransitionSlide,
    BlockQuestion,
    BlockPagination,
    ValidationError,
    HeaderTemplate,
  },

  mixins: [ValidationMixin],

  props: {
    code: {
      type: String,
      required: true,
    },

    formBlocks: {
      type: Array,
      required: true,
    },

    paginationBullets: {
      type: String,
      default: 'light',
    },

    submissionErrors: {
      type: Object,
      default: undefined,
    },

    answers: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      newAnswers: [],
      currentBlock: undefined,
      loadAnswers: false,
      currentTransitionSide: 'left',
    };
  },

  computed: {
    ...get(store, [
      'getFirstIncompleteBlock',
      'getAvailableQuestions',
      'getAnswerByQuestion',
      'getBlockQuestionsAnswer',
      'getBlock',
      'getBlockByQuestionKey',
    ]),

    blocks: get(`${store}/blocks`),

    currentBlockId() {
      return this.currentBlock?.id;
    },

    formVisible() {
      return this.currentBlock && this.blocks;
    },

    blockTemplate() {
      return this.currentBlock?.template;
    },

    title() {
      if (!this.currentBlock || !this.currentBlock?.title) return undefined;
      return `BLOCK.${this.currentBlock.title}`;
    },

    questions() {
      if (!this.currentBlock) return undefined;
      return this.getAvailableQuestions(this.currentBlock.questions);
    },

    currentHeaderTransitionSide() {
      return this.currentTransitionSide === 'left' ? 'top' : 'bottom';
    },
  },

  watch: {
    currentBlock(newValue, oldValue) {
      setTimeout(() => {
        this.focusFirstQuestionnaireItem();
      }, 1000);

      if (!this.questions.length) {
        this.redirectForEmptyblock(newValue?.order, oldValue?.order);
      }
    },

    loadAnswers: {
      inmediate: true,
      handler() {
        this.currentBlock = this.getCurrentBlock();
      },
    },

    submissionErrors: {
      deep: true,
      handler(errors) {
        if (errors) {
          this.redirectToBlockErrors();
        }
      },
    },

    answers: {
      deep: true,
      inmediate: true,
      handler(answers) {
        this.$store.set(`${store}/answers`, answers);
        this.loadAnswers = true;
      },
    },
  },

  async created() {
    await this.$store.dispatch(`${store}/loadBlocks`, this.formBlocks);
  },

  mounted() {
    this.currentBlock = this.getCurrentBlock();
  },

  methods: {
    redirectForEmptyblock(newValueOrder, oldValueOrder) {
      if (newValueOrder === 1) return;
      if (!newValueOrder || !oldValueOrder) return;

      const direction = (newValueOrder > oldValueOrder) ? 1 : -1;
      const transition = (newValueOrder > oldValueOrder) ? 'left' : 'right';
      const blockId = this.getBlockByOffset(direction);
      this.changeBlock({ blockId, transition });
    },

    getBlockByOffset(offset) {
      const blockIndex = this.getBlockIndex();
      if (blockIndex < 0) return undefined;

      return this.getBlockByIndex(blockIndex + offset)?.id;
    },

    getBlockIndex() {
      return this.blocks.findIndex(block => block.id === this.currentBlockId);
    },

    getBlockByIndex(blockIndex) {
      return ((typeof this.blocks[blockIndex] !== 'undefined') ? this.blocks[blockIndex] : undefined);
    },


    getCurrentBlock() {
      const block = this.getFirstIncompleteBlock();

      return block ?? this.blocks[0];
    },

    focusFirstQuestionnaireItem() {
      try {
        const firstQuestion = document.querySelector('.BlockQuestion .question:not(.Statement)');
        if (!firstQuestion) throw new Error('Empty questions');

        const element = firstQuestion.querySelector(':is(input, textarea)');
        if (!element) throw new Error('No input question');

        element.focus();
      } catch (e) {
        this.focusNextButton();
      }
    },

    focusNextButton() {
      const button = document.querySelector('.pagination-button-next button');
      if (button) button.focus();
    },

    getAnswer(questionId, questionKey) {
      const answer = this.getAnswerByQuestion(questionId, questionKey);
      return answer?.answer;
    },

    async answerSelected({ questionId, questionKey, answer }) {
      // this.addAnswerToNewAnswers({ questionId, questionKey, answer });

      this.$emit('save-answer', { questionId, questionKey, answer });
    },

    // addAnswerToNewAnswers(newAnswer) { // todo move newAnswers to store?
    //   const answers = this.newAnswers.filter(answer => answer.questionId !== newAnswer.questionId);
    //   this.newAnswers = [...answers, newAnswer];
    // },

    async changeBlock({ blockId, transitionSide }) {
      if (!blockId) return;

      if (this.ckeckAllBlockQuestions() || transitionSide === 'left') {
        this.$emit('change-block');

        this.currentTransitionSide = transitionSide;
        this.currentBlock = this.getBlock(blockId);
      }
    },

    async submitBlock() {
      if (this.ckeckAllBlockQuestions()) {
        // this.send(this.newAnswers);
        // this.newAnswers = [];

        this.finishForm();
      }
    },

    ckeckAllBlockQuestions() {
      const blockQuestionsAnswer = this.getBlockQuestionsAnswer(this.currentBlock.id);
      this.errors = [];
      const validations = blockQuestionsAnswer.map((questionAnswer) => {
        if (!questionAnswer?.validations) return true;

        const valids = questionAnswer?.validations.map(validation => this.checkSpecificValidation(validation, questionAnswer));
        return valids.every(valid => valid);
      });

      return validations.every(validation => validation);
    },

    isTemporaryAnswer(questionId) {
      return this.newAnswers.some(answer => answer.questionId === questionId);
    },

    finishForm() { // todo only one: (send/finishForm)
      this.$emit('finish-form');
    },

    async redirectToBlockErrors() {
      const error = this.getFirstSubmissionError();
      const block = this.getBlockByQuestionKey(error.key);

      if (!block) return;

      await this.changeBlock({ blockId: block.id, transitionSide: 'right' });
      setTimeout(() => {
        this.forceFormFieldsErrorsAfterSubmit();
      }, 1000);
    },

    getFirstSubmissionError() {
      const submissionErrors = Object.entries(this.submissionErrors);
      if (!submissionErrors) return undefined;

      const firstError = submissionErrors[0];
      return { key: firstError[0], value: firstError[1] };
    },

    forceFormFieldsErrorsAfterSubmit() {
      const currentForm = document.forms[0];
      Object.entries(this.submissionErrors).forEach((error) => {
        const key = error[0];
        const message = error[1];
        const field = currentForm.querySelector(`[name=${key}]`);
        if (field) this.forceErrorElement(field, message);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.FormBlocks{
  display: grid;
  grid-template-rows: auto 1fr;

  .header{
    border-left: 4px solid $color-secondary;
    text-transform: uppercase;
    padding: $spacing-2xs $spacing-xs;

    a{
      text-decoration: none;
      color: $color-secondary-lighter;
    }

    span{
      text-transform: initial;
    }
  }

  .questions{
    width: 100%;
    margin: 0 auto;
    display: grid;
    align-content: start;
    gap: $spacing-m;

    @each $tag in ('A', 'B', 'C', 'D', 'E'){
      .BlockQuestion.#{$tag} {
        grid-area: #{$tag};
      }
    }

    @media #{$breakpoint-md-max}{
      grid-template-areas: "none"!important;
      .BlockQuestion{
        grid-area: auto!important;
      }
    }
  }

  .loading-content{
    width: 100%;
    @include empty-content(100%);
  }
}
</style>

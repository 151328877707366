<template>
  <div class="BlockPagination">
    <div class="pagination-button-pre">
      <BaseButton
        v-if="previous"
        class="previous-button"
        v-bind="{title: 'volver a la seccion anterior'}"
        variation="secondary"
        @click="changeBlock({blockId: previous, transitionSide: 'left'})"
      >
        <font-awesome-icon
          class="arrow"
          :icon="['fas', 'chevron-left']"
        />
      </BaseButton>
    </div>

    <div class="pagination-content">
      <slot />
    </div>

    <PaginationBullets
      class="pagination-bullets"
      :bullets="blocks.length"
      :current="currentBlock"
      :theme="theme"
    />

    <div class="pagination-button-next">
      <BaseButton
        v-if="next"
        class="next-button"
        v-bind="{title: 'ir a la siguiente seccion'}"
        @click="changeBlock({blockId: next, transitionSide: 'right'})"
      >
        <font-awesome-icon
          class="arrow"
          :icon="['fas', 'chevron-right']"
        />
      </BaseButton>

      <BaseButton
        v-else
        class="finish-button"
        v-bind="{title: 'enviar formulario'}"
        @click="submitBlock(next)"
      >
        <font-awesome-icon
          class="arrow"
          :icon="['fas', 'paper-plane']"
        />
      </BaseButton>
    </div>
  </div>
</template>

<script>
import PaginationBullets from '@/components/blocks/PaginationBullets';

const OFFSETS = {
  PREVIOUS: -1,
  NEXT: 1,
};

export default {
  components: {
    PaginationBullets,
  },

  props: {
    blocks: {
      type: Array,
      required: true,
    },

    blockId: {
      type: Number,
      default: undefined,
    },
  },

  data() {
    return {
      keyboardPaginationEnable: true,
    };
  },

  computed: {
    previous() {
      return this.getBlockByOffset(OFFSETS.PREVIOUS);
    },

    next() {
      return this.getBlockByOffset(OFFSETS.NEXT);
    },

    currentBlock() {
      if (!this.blockId) return undefined;

      return this.blocks?.findIndex(block => block.id === this.blockId);
    },
  },

  // created() {
  // const keyboadCodes = [39, 37];
  // window.addEventListener('keydown', ({ keyCode }) => {
  //   if (this.keyboardPaginationEnable && keyboadCodes.includes(keyCode)) {
  //     this.changeBlockWithKeyboad(keyCode);
  //     this.keyboardPaginationEnable = false;
  //   }
  // });
  // window.addEventListener('keyup', () => {
  //   this.keyboardPaginationEnable = true;
  // });
  // },

  methods: {
    // changeBlockWithKeyboad(keyCode) {
    //   if (keyCode === 37) {
    //     this.changeBlock({ blockId: this.previous, transitionSide: 'left' });
    //   }
    //   if (keyCode === 39) {
    //     this.changeBlock({ blockId: this.next, transitionSide: 'right' });
    //   }
    // },

    getBlockByOffset(offset) {
      const blockIndex = this.getBlockIndex();
      if (blockIndex < 0) return { previous: undefined, next: undefined };

      return this.getBlockByIndex(blockIndex + offset)?.id;
    },

    getBlockIndex() {
      return this.blocks.findIndex(block => block.id === this.blockId);
    },

    getBlockByIndex(blockIndex) {
      return ((typeof this.blocks[blockIndex] !== 'undefined') ? this.blocks[blockIndex] : undefined);
    },

    changeBlock({ blockId, transitionSide }) {
      this.$emit('change-block', { blockId, transitionSide });
    },

    submitBlock(blockId) {
      this.$emit('submit-block', blockId);
    },
  },
};
</script>

<style lang="scss" scoped>
.BlockPagination{
  padding: $spacing-l $spacing-s;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "pagination-bullets pagination-bullets pagination-bullets"
    "pagination-button-pre pagination-content pagination-button-next";

  width: 100%;
  height: 100%;
  margin: auto;

  @each $tag in ('pagination-button-pre', 'pagination-content', 'pagination-bullets', 'pagination-button-next'){
    .#{$tag} {
      grid-area: #{$tag};
    }
  }

  .pagination-bullets{
    padding: $spacing-m;
  }

  .pagination-content{
    display: grid;
    align-items: start;
    justify-items: stretch;
    align-self: stretch;
    padding: $spacing-3xl $spacing-2xl;
  }

  .pagination-button-pre,
  .pagination-button-next{
    display: grid;
    place-items: center;
  }

  .pagination-button-pre{
    margin-right: -$spacing-l;
  }
  .pagination-button-next{
    margin-left: -$spacing-l;
  }

  .previous-button,
  .next-button,
  .finish-button{
    font-size: $font-size-2xl;
    width: 50px;
    height: 50px;
    padding: 0;
    position: relative;
    z-index: 2;
    box-shadow: $shadow-elevation-1;
  }


  @media #{$breakpoint-lg-max}{
    grid-template-areas:
    "pagination-content pagination-content pagination-content"
    "pagination-button-pre pagination-bullets pagination-button-next";
    grid-template-rows: 1fr auto;
    align-items: center;

    .pagination-content,
    .pagination-button-pre,
    .pagination-button-next{
      padding: $spacing-s;
    }
  }

  @media #{$breakpoint-sm-max}{
    padding: $spacing-xs $spacing-2xs;
  }

  @media #{$breakpoint-sm-max}{
    .pagination-bullets{
      display: none;
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "Register",
      on: {
        input: function($event) {
          return _vm.validation($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "form-body" },
        [
          _c("h1", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("REGISTER.TITLE")) + " ")
          ]),
          _c("p", { staticClass: "description" }, [
            _vm._v(" " + _vm._s(_vm.$t("REGISTER.DESCRIPTION")) + " ")
          ]),
          _vm._l(_vm.fields, function(field, i) {
            return _c(
              "BaseInput",
              _vm._b(
                {
                  key: i,
                  attrs: { theme: "light" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "error",
                        fn: function() {
                          return [
                            _c("ValidationError", {
                              attrs: { errors: _vm.errors, name: field.name }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: field.value,
                    callback: function($$v) {
                      _vm.$set(field, "value", $$v)
                    },
                    expression: "field.value"
                  }
                },
                "BaseInput",
                field,
                false
              )
            )
          }),
          _c(
            "div",
            { staticClass: "policies", attrs: { id: "policies" } },
            [
              _c("BaseSwitcher", {
                attrs: { tag: "privacy-policies" },
                on: {
                  input: function($event) {
                    _vm.privacyPolicies = $event
                  }
                },
                model: {
                  value: _vm.privacyPolicies,
                  callback: function($$v) {
                    _vm.privacyPolicies = $$v
                  },
                  expression: "privacyPolicies"
                }
              }),
              _c("p", {
                domProps: { innerHTML: _vm._s(_vm.$t("POLICIES.OPEN_BUTTON")) }
              })
            ],
            1
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "form-footer" },
        [
          _c(
            "BaseButton",
            {
              staticClass: "register-btn",
              attrs: { size: "l", variation: "primary" },
              on: { click: _vm.submit }
            },
            [_vm._v(" " + _vm._s(_vm.$t("LOGIN.REGISTER")) + " ")]
          ),
          _c(
            "BaseButton",
            {
              staticClass: "register",
              attrs: { size: "s", variation: "tertiary" },
              on: {
                click: function($event) {
                  return _vm.$emit("toggle-view", "Login")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("LOGIN.RETURN")) + " ")]
          )
        ],
        1
      ),
      _vm.modalVisible
        ? _c(
            "BaseModal",
            {
              attrs: { "header-visible": false, size: "m" },
              on: { close: _vm.closeModal }
            },
            [
              _c("PoliciesPanel", {
                on: {
                  close: _vm.closeModal,
                  "accept-policies": _vm.acceptPolicies
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
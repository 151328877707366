<template>
  <div class="EvaluationResultsView">
    <HeaderTemplate image="results-header.jpg">
      <div class="header">
        <h1 class="title">
          {{ $t('EVALUATION_RESULTS.TITLE') }}
        </h1>

        <p>
          {{ $t('EVALUATION_RESULTS.SUBTITLE') }}
        </p>

        <p
          v-if="type"
          v-html="$tc('EVALUATION_RESULTS.COMPANY', null, [$t(`COMPANY_TYPE.${type}`)])"
        />
      </div>
    </HeaderTemplate>

    <div class="content fill-content">
      <div
        v-if="total"
        class="totals"
      >
        <BarCard
          :title="`${$t('EVALUATION_RESULTS.SELF.TITLE')} (${total.value}%)`"
          :value="total.value"
          color="hsl(265, 90%, 60%)"
        />

        <p class="title">
          {{ $t('EVALUATION_RESULTS.TOTALS.TITLE') }}
        </p>

        <BarCard
          :title="`${$t('EVALUATION_RESULTS.REFERENCE.TITLE')} (${total.reference}%)`"
          :value="total.reference"
          color="hsl(37, 100%, 53%)"
        />
      </div>

      <div class="blocks">
        <div
          v-for="({block, value, reference, pillars}, i) in results"
          :key="i"
          class="block"
        >
          <h2 class="block-title">
            {{ $t(`RESULTS_BLOCK.${block}`) }}
          </h2>

          <div class="block-content">
            <PieCard
              class="global-card"
              :title="'GLOBAL'"
              size="l"
              :values="[{value, text: $t('EVALUATION_RESULTS.SELF.TITLE')}, {value:reference, text: $t('EVALUATION_RESULTS.REFERENCE.TITLE')}]"
            />

            <div class="pillars">
              <PieCard
                v-for="(pillar, j) in pillars"
                :key="j"
                :title="$t(`PILLAR.${pillar.pillar}.TITLE`)"
                :icon="getIcon(i, j)"
                :values="[{value: pillar.value, text: $t('EVALUATION_RESULTS.SELF.TITLE')}, {value:pillar.reference, text: $t('EVALUATION_RESULTS.REFERENCE.TITLE')}]"
              />
            </div>
          </div>
        </div>
      </div>

      <BaseButton
        class="strategies-button"
        @click="$router.push({ path: '/strategies' })"
      >
        {{ $t('EVALUATION_RESULTS.BUTTON') }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import HeaderTemplate from '@/components/common/HeaderTemplate';
import PieCard from '@/components/results/PieCard';
import BarCard from '@/components/results/BarCard';

const STORE = 'resultsModule';


export default {
  components: {
    HeaderTemplate,
    PieCard,
    BarCard,
  },

  data() {
    return {
      icons: ['far-lightbulb', 'fas-user-tie', 'fas-globe', 'fas-users', 'fas-coins', 'fas-laptop', 'fas-network-wired', 'fas-cogs', 'fas-server'],
    };
  },

  computed: {
    type: get(`${STORE}/type`),
    total: get(`${STORE}/total`),
    results: get(`${STORE}/results`),
  },

  async created() {
    await this.$store.dispatch(`${STORE}/fetchResults`);
  },

  methods: {
    getIcon(i, j) {
      const previousIndex = (i > 0) ? i - 1 : i;
      const previousPillars = this.results[previousIndex].pillars.length * i;
      return this.icons[previousPillars + j];
    },
  },
};
</script>

<style lang="scss" scoped>
.EvaluationResultsView{
  background: $color-neutral-lighter;

  .header{
    .title{
      text-transform: uppercase;
      color: $color-secondary-lighter;
      font-size: $font-size-xl;

    }
    ::v-deep strong{
      color: $color-primary-lighter;
    }
  }

  .totals{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: center;
    margin-bottom: $spacing-m;
    padding: $spacing-xl $spacing-m;
    border-bottom: 1px solid $color-neutral-mid;

    .title{
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .blocks{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: $spacing-s;

    .block{
      padding: $spacing-s;
      margin-bottom: $spacing-m;
      background: $color-neutral-lightest;
      border-radius: $border-radius-m;

      .block-title{
        grid-column: 1/3;
        padding: $spacing-s 0;
        font-size: $font-size-l;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
      }

      .block-content{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        gap: $spacing-s;

        .global-card{
          border: 2px solid $color-primary;
          outline: 2px solid $color-secondary;
          outline-offset: -6px;
        }

        .pillars{
          display: grid;
          grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
          gap: $spacing-s;
        }
      }
    }
  }

  .strategies-button{
    margin: auto;
    margin-bottom: $spacing-m;
  }
}
</style>

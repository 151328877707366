var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "EvaluationResultsView" },
    [
      _c("HeaderTemplate", { attrs: { image: "results-header.jpg" } }, [
        _c("div", { staticClass: "header" }, [
          _c("h1", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("EVALUATION_RESULTS.TITLE")) + " ")
          ]),
          _c("p", [
            _vm._v(" " + _vm._s(_vm.$t("EVALUATION_RESULTS.SUBTITLE")) + " ")
          ]),
          _vm.type
            ? _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$tc("EVALUATION_RESULTS.COMPANY", null, [
                      _vm.$t("COMPANY_TYPE." + _vm.type)
                    ])
                  )
                }
              })
            : _vm._e()
        ])
      ]),
      _c(
        "div",
        { staticClass: "content fill-content" },
        [
          _vm.total
            ? _c(
                "div",
                { staticClass: "totals" },
                [
                  _c("BarCard", {
                    attrs: {
                      title:
                        _vm.$t("EVALUATION_RESULTS.SELF.TITLE") +
                        " (" +
                        _vm.total.value +
                        "%)",
                      value: _vm.total.value,
                      color: "hsl(265, 90%, 60%)"
                    }
                  }),
                  _c("p", { staticClass: "title" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("EVALUATION_RESULTS.TOTALS.TITLE")) +
                        " "
                    )
                  ]),
                  _c("BarCard", {
                    attrs: {
                      title:
                        _vm.$t("EVALUATION_RESULTS.REFERENCE.TITLE") +
                        " (" +
                        _vm.total.reference +
                        "%)",
                      value: _vm.total.reference,
                      color: "hsl(37, 100%, 53%)"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "blocks" },
            _vm._l(_vm.results, function(ref, i) {
              var block = ref.block
              var value = ref.value
              var reference = ref.reference
              var pillars = ref.pillars
              return _c("div", { key: i, staticClass: "block" }, [
                _c("h2", { staticClass: "block-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("RESULTS_BLOCK." + block)) + " ")
                ]),
                _c(
                  "div",
                  { staticClass: "block-content" },
                  [
                    _c("PieCard", {
                      staticClass: "global-card",
                      attrs: {
                        title: "GLOBAL",
                        size: "l",
                        values: [
                          {
                            value: value,
                            text: _vm.$t("EVALUATION_RESULTS.SELF.TITLE")
                          },
                          {
                            value: reference,
                            text: _vm.$t("EVALUATION_RESULTS.REFERENCE.TITLE")
                          }
                        ]
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "pillars" },
                      _vm._l(pillars, function(pillar, j) {
                        return _c("PieCard", {
                          key: j,
                          attrs: {
                            title: _vm.$t("PILLAR." + pillar.pillar + ".TITLE"),
                            icon: _vm.getIcon(i, j),
                            values: [
                              {
                                value: pillar.value,
                                text: _vm.$t("EVALUATION_RESULTS.SELF.TITLE")
                              },
                              {
                                value: pillar.reference,
                                text: _vm.$t(
                                  "EVALUATION_RESULTS.REFERENCE.TITLE"
                                )
                              }
                            ]
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ])
            }),
            0
          ),
          _c(
            "BaseButton",
            {
              staticClass: "strategies-button",
              on: {
                click: function($event) {
                  return _vm.$router.push({ path: "/strategies" })
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("EVALUATION_RESULTS.BUTTON")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
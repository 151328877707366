var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "UserCard" }, [
    _c("img", { attrs: { src: _vm.image, alt: "profile-image" } }),
    _c("div", { staticClass: "information" }, [
      _c("div", { staticClass: "main-data" }, [
        _c("p", { staticClass: "name" }, [
          _vm._v(" " + _vm._s(_vm.name) + " ")
        ]),
        _c("p", { staticClass: "email" }, [
          _vm._v(" " + _vm._s(_vm.email) + " ")
        ])
      ]),
      _c("div", { staticClass: "secondary-data" }, [
        _c(
          "p",
          { staticClass: "position" },
          [
            _c("font-awesome-icon", {
              staticClass: "icon",
              attrs: { icon: ["fas", "user-tie"] }
            }),
            _vm._v(" " + _vm._s(_vm.position) + " ")
          ],
          1
        ),
        _c(
          "p",
          { staticClass: "company" },
          [
            _c("font-awesome-icon", {
              staticClass: "icon",
              attrs: { icon: ["far", "building"] }
            }),
            _vm._v(" " + _vm._s(_vm.company) + " ")
          ],
          1
        ),
        _c(
          "p",
          { staticClass: "linkedin" },
          [
            _c("font-awesome-icon", {
              staticClass: "icon",
              attrs: { icon: ["fab", "linkedin"] }
            }),
            _c("a", { attrs: { href: _vm.linkedin } }, [
              _vm._v(_vm._s(_vm.linkedin))
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import axios from 'axios';
import store from '@/store';

const LOCAL_STORAGE_TOKEN = 'token';
let refreshTokenRequest = true;

const delayedResponse = response => new Promise(resolve => setTimeout(() => resolve(response.data), 500));

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// const controller = new AbortController();
// const controllerSignal = controller.signal;

axios.interceptors.request.use(
  (config) => {
    const configuration = config;
    configuration.headers.Authorization = `Bearer ${localStorage.getItem(LOCAL_STORAGE_TOKEN)}`;
    // configuration.signal = controllerSignal;
    return configuration;
  },

  error => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => {
    //* to test
    // console.log('response', response.config.url, ' => ', response.config.headers.Authorization);
    if (process.env.NODE_ENV === 'offline') {
      const isUnauthorized = response.data?.status === 401;
      const isLoginRequest = /login/g.test(response.config.url);

      if (isUnauthorized && !isLoginRequest) {
        if (refreshTokenRequest) {
          refreshTokenRequest = false;
          return store.dispatch('authModule/refreshToken')
            .then(() => axios.request(response.config));
        }

        refreshTokenRequest = true;
        store.dispatch('authModule/logout');
      }

      return delayedResponse(response);
    }

    return response.data;
  },

  (error) => {
    const isUnauthorized = error.response?.status === 401;
    const isLoginRequest = /login|logout/g.test(error.response.config.url);

    if (isUnauthorized && !isLoginRequest) {
      if (refreshTokenRequest) {
        refreshTokenRequest = false;
        return store.dispatch('authModule/refreshToken')
          .then(() => axios.request(error.response.config));
      }

      refreshTokenRequest = true;
      // controller.abort();
      store.dispatch('authModule/logout');
    }

    return Promise.reject(error);
  },
);


export default axios;

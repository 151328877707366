/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';
import axios from 'axios';

const getDefaultState = () => ({
  loading: false,
  questionnaires: [],
  closed: false,
  autosave: false,
  blocks: undefined,
  errors: undefined,
  answers: [],
  newAnswers: [],
});

const state = () => getDefaultState();

export const getters = {
  isLoading: state => state.loading,
};

export const mutations = {
  ...make.mutations(state),

  SET_ANSWER(state, newAnswer) {
    if (newAnswer) {
      const answers = state.answers.filter(answer => (answer.questionKey !== newAnswer.questionKey));
      state.answers = [...answers, newAnswer];
    }
  },

  SET_NEW_ANSWER(state, newAnswer) {
    if (newAnswer) {
      const answers = state.newAnswers.filter(answer => (answer.questionKey !== newAnswer.questionKey));
      state.newAnswers = [...answers, newAnswer];
    }
  },
};

export const actions = {
  initModule({ commit }) {
    commit('SET_AUTOSAVE', false);
    commit('SET_BLOCKS', undefined);
    commit('SET_ANSWERS', []);
    commit('SET_NEW_ANSWERS', []);
  },

  async fetchQuestionnaires({ commit }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get('/evaluation');
      if (error) throw new Error(data);

      console.log(data);
      commit('SET_QUESTIONNAIRES', data.questionnaires);
      commit('SET_CLOSED', data.closed);
    } catch (e) {
      // console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchQuestions({ commit }, questionnaireKey) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get(`/questionnaire/${questionnaireKey}/questions`);
      if (error) throw new Error(data);

      commit('SET_BLOCKS', data.blocks);
      commit('SET_AUTOSAVE', data.autosave);
    } catch (e) {
      // console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchAnswers({ commit }, questionnaireKey) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get(`/questionnaire/${questionnaireKey}/answers`);
      if (error) throw new Error(data);

      commit('SET_ANSWERS', data ?? []);
    } catch (e) {
      // console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async sendAnswers({ state, commit }) {
    try {
      commit('SET_LOADING', true);

      if (state.newAnswers.length > 0) {
        const { data, error } = await axios.post('/questionnaire/send', { answers: state.newAnswers });
        if (error) {
          commit('SET_ERRORS', data?.errors);
          throw new Error(data.message);
        }

        commit('SET_NEW_ANSWERS', []);
        window.Vue.$toast.success(data);
      }
    } catch (e) {
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async saveAnswer({ commit }, answer) {
    commit('SET_LOADING', true);

    commit('SET_NEW_ANSWER', answer);
    commit('SET_ANSWER', answer);

    commit('SET_LOADING', false);
  },

  // async saveAnswers({ commit }, answers = []) {
  //   commit('SET_LOADING', true);

  //   answers.forEach(answer => commit('SET_ANSWER', answer));

  //   commit('SET_LOADING', false);
  // },

  async sendFileAnswer({ commit }, { questionId, questionKey, file }) {
    try {
      commit('SET_LOADING', true);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('questionId', questionId);
      const options = { headers: { 'Content-Type': 'multipart/form-data' } };
      const { data, error } = await axios.post('/questionnaire/file', formData, options);
      if (error) {
        commit('SET_ERRORS', data?.errors);
        throw new Error(data.message);
      }

      console.log(data);
      const answer = { questionId, questionKey, answer: data.image };
      commit('SET_ANSWER', answer);
    } catch (e) {
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async closeEvaluationModule({ commit }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/close-evaluation');
      if (error) throw new Error(data.message);

      commit('SET_CLOSED', true);
    } catch (e) {
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

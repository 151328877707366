var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "SmartProjectView" }, [
    _c("h2", { staticClass: "smart-title" }, [
      _vm._v(
        " " +
          _vm._s(
            _vm.$t(
              "SMART_PROJECT.KEY_FACTOR." +
                _vm.factor +
                ".STATE." +
                _vm.state +
                ".TITLE"
            )
          ) +
          " "
      )
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "initial-final" }, [
          _c("div", { staticClass: "initial" }, [
            _c("p", [
              _c("strong", [
                _vm._v(_vm._s(_vm.$t("SMART_PROJECT.INITIAL.TITLE")))
              ])
            ]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "SMART_PROJECT.KEY_FACTOR." +
                      _vm.factor +
                      ".STATE." +
                      _vm.state +
                      ".INITIAL.TITLE"
                  )
                ) +
                " "
            )
          ]),
          _c("div", { staticClass: "final" }, [
            _c("p", [
              _c("strong", [
                _vm._v(_vm._s(_vm.$t("SMART_PROJECT.FINAL.TITLE")))
              ])
            ]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "SMART_PROJECT.KEY_FACTOR." +
                      _vm.factor +
                      ".STATE." +
                      _vm.state +
                      ".FINAL.TITLE"
                  )
                ) +
                " "
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "milestones" },
          _vm._l(3, function(milestone) {
            return _c(
              "div",
              { key: milestone, staticClass: "milestone" },
              [
                _c("div", { staticClass: "title" }, [
                  _c("p", [
                    _c("strong", [_vm._v(_vm._s("Hito " + milestone))])
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "SMART_PROJECT.KEY_FACTOR." +
                            _vm.factor +
                            ".STATE." +
                            _vm.state +
                            ".MILESTONE." +
                            milestone +
                            ".TITLE"
                        )
                      ) +
                      " "
                  )
                ]),
                _vm._l(3, function(task) {
                  return _c("div", { key: task, staticClass: "task" }, [
                    _c("p", [_c("strong", [_vm._v(_vm._s("Tarea " + task))])]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "SMART_PROJECT.KEY_FACTOR." +
                              _vm.factor +
                              ".STATE." +
                              _vm.state +
                              ".MILESTONE." +
                              milestone +
                              ".TASK." +
                              task
                          )
                        ) +
                        " "
                    )
                  ])
                })
              ],
              2
            )
          }),
          0
        ),
        _c(
          "BaseButton",
          _vm._b(
            { staticClass: "select-button", on: { click: _vm.choose } },
            "BaseButton",
            { disabled: _vm.disabled },
            false
          ),
          [
            _c("div", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.selected
                      ? _vm.$t("DESELECT.BUTTON")
                      : _vm.$t("SELECT.BUTTON")
                  ) +
                  " "
              ),
              _c("span", { staticClass: "points" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      "( " +
                        (_vm.selected ? "+" : "-") +
                        " " +
                        _vm.$tc("POINTS.LABEL", _vm.points, {
                          points: _vm.points
                        }) +
                        " )"
                    ) +
                    " "
                )
              ])
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "TheFooter" }, [
    _c("div", { staticClass: "footer window-fit" }, [
      _c("img", {
        staticClass: "image",
        attrs: {
          src: require("@/assets/images/institucional.jpg"),
          alt: "Institucional"
        }
      }),
      _c("p", { staticClass: "develop-by" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$tc("FOOTER.DEVELOP_BY", null, [new Date().getFullYear()])
            ) +
            " "
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "HeaderTemplate",
      style: {
        "background-image":
          "url(" + require("@/assets/images/" + _vm.image) + ")"
      }
    },
    [
      _c(
        "div",
        { staticClass: "fill-content" },
        [
          _c(
            "TransitionSlide",
            { attrs: { side: _vm.transition } },
            [_vm._t("default")],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <TransitionSlide side="bottom">
    <div
      class="CloseEvaluationCard"
      :class="[state]"
    >
      <div class="content">
        <p class="title">
          {{ $t(`CLOSE_EVALUATION_CARD.RESULTS.TITLE`) }}
        </p>

        <p class="block">
          {{ $t(`CLOSE_EVALUATION_CARD.RESULTS.DESCRIPTION`) }}
        </p>
      </div>

      <font-awesome-icon
        :icon="['far','chart-bar']"
        class="icon fa-4x"
      />
    </div>
  </TransitionSlide>
</template>

<script>
import TransitionSlide from '@/transitions/TransitionSlide';

export default {
  components: {
    TransitionSlide,
  },

  props: {
    available: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    state() {
      return this.available ? 'available' : 'disabled';
    },
  },
};
</script>

<style lang="scss" scoped>
.CloseEvaluationCard{
  &.disabled{
    --card-border: none;
    --card-background: #{$color-neutral-mid-dark};
    --title-color: #fff;
    --subtitle-color: #fff;
    --icon-color: #{adjust-color($color-neutral-light, $alpha: -.8)};
    pointer-events: none;
  }

  &.available{
    --card-border: none;
    --card-background: linear-gradient(132deg, #{$color-secondary-dark}, #{$color-secondary-lighter});
    --title-color: #fff;
    --subtitle-color: #fff;
    --icon-color:rgb(255 235 204);
    --icon-color: #{adjust-color($color-secondary-lightest, $alpha: -.6)};
  }

  display: grid;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: $shadow-elevation-1;
  background: var(--card-background);
  border: var(--card-border);
  position: relative;
  z-index: 1;
  transition: all .3s ease;

  .content{
    padding: $spacing-xs;
    .title{
      font-size: $font-size-s;
      text-transform: uppercase;
      font-weight: 500;
      color: var(--title-color);
      position: relative;
    }

    .block{
      font-size: $font-size-xs;
      color: var(--subtitle-color);
    }
  }

  .icon{
    position: absolute;
    font-size: 6rem;
    transform: rotate(-10deg);
    bottom: -1.25rem;
    right: .5rem;
    color:  var(--icon-color);
    z-index: -1rem;
  }

  &:hover{
    cursor: pointer;
  }
}
</style>

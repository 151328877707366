var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SmartProject", class: { selected: _vm.selected } },
    [
      _c("p", { staticClass: "title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "SMART_PROJECT.KEY_FACTOR." +
                  _vm.keyFactor +
                  ".STATE." +
                  _vm.state +
                  ".TITLE"
              )
            ) +
            " "
        )
      ]),
      _c("p", { staticClass: "factor" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "PILLAR." +
                  _vm.pillar +
                  ".KEY_FACTOR." +
                  _vm.keyFactor +
                  ".TITLE"
              )
            ) +
            " "
        )
      ]),
      _c("p", { staticClass: "pillar" }, [
        _vm._v(" " + _vm._s(_vm.$t("PILLAR." + _vm.pillar + ".TITLE")) + " ")
      ]),
      _c(
        "div",
        { staticClass: "stars" },
        _vm._l(3, function(star) {
          return _c("font-awesome-icon", {
            key: star,
            attrs: { icon: [star <= _vm.state ? "fas" : "far", "star"] }
          })
        }),
        1
      ),
      _c(
        "span",
        { staticClass: "points" },
        [
          _vm._v(" " + _vm._s(_vm.points) + " "),
          _c("font-awesome-icon", { attrs: { icon: "coins" } })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
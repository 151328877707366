/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';

const getDefaultState = () => ({
  loading: false,
  blocks: undefined,
  answers: [],
});

const state = () => getDefaultState();

export const getters = {
  isLoading: state => state.loading,

  getBlocks: state => () => state.blocks,

  getFirstIncompleteBlock: (state, getters) => () => {
    const block = state.blocks.find(({ questions }) => !questions.every(({ key, validations }) => {
      const isRequired = getters.hasValidation(validations, 'required');
      return !isRequired || (isRequired && !!getters.getAnswerByQuestionKey(key));
    }));
    return block;
  },

  hasValidation: () => (validations, validation) => validations.some(arr => validation in arr),

  getBlock: state => blockId => state.blocks.find(block => block.id === blockId),

  getBlockByQuestionKey: state => (questionKey) => {
    const block = state.blocks.find(block => block.questions.find(question => question.key === questionKey));
    return block;
  },

  getAnswers: state => () => state.answers,

  getAnswerByQuestion: state => (questionId, questionKey) => {
    const answer = state.answers?.find(answer => parseInt(answer.questionId, 10) === parseInt(questionId, 10) || (answer.questionKey === questionKey));
    return answer;
  },

  getAnswerByQuestionKey: state => questionKey => state.answers?.find(answer => answer.questionKey === questionKey),

  getBlockQuestionsAnswer: (state, getters) => (blockId) => {
    const block = getters.getBlock(blockId);

    const questions = getters.getAvailableQuestions(block.questions);
    if (!block || !questions) return undefined;

    return questions.map((question) => {
      const answer = getters.getAnswerByQuestion(question.id, question.key);
      return Object.assign(question, { answer: answer?.answer });
    });
  },

  getAvailableQuestions: (state, getters) => questions => questions?.filter(question => getters.checkQuestionDependence(question.dependences)),

  checkQuestionDependence: (state, getters) => (dependences) => {
    if (!dependences) return true;

    return dependences.some(dependence => dependence.every((dependency) => {
      const currentAnswer = getters.getAnswerByQuestion(dependency?.questionId, dependency?.questionKey);

      if (!currentAnswer) return false;

      if (dependency?.answer !== undefined) {
        if (dependency?.field && (typeof currentAnswer.answer === 'object')) {
          return currentAnswer.answer[dependency.field].toString() === dependency.answer.toString();
        }

        if (Array.isArray(dependency.answer)) {
          return dependency.answer.every(dependency => currentAnswer.answer.includes(dependency.toString()));
        }

        return currentAnswer.answer.toString() === dependency.answer.toString();
      }

      if (dependency?.regexp) {
        const regexp = new RegExp(dependency.regexp, 'g');
        return regexp.test(currentAnswer.answer);
      }

      return true;
    }));
  },
};

export const mutations = {
  ...make.mutations(state),

  // SET_ANSWER(state, newAnswer) {
  //   const answers = state.answers.filter(answer => (answer.questionKey !== newAnswer.questionKey));
  //   state.answers = [...answers, newAnswer];
  // },
};

export const actions = {
  async loadBlocks({ commit }, blocks) {
    commit('SET_LOADING', true);

    commit('SET_BLOCKS', blocks);

    commit('SET_LOADING', false);
  },

  // async saveAnswers({ commit }, answers) {
  //   commit('SET_LOADING', true);

  //   if (answers) {
  //     answers.forEach(answer => commit('SET_ANSWER', answer));
  //   }

  //   commit('SET_LOADING', false);
  // },

  // async saveAnswer({ commit }, answer) { // todo remove
  //   commit('SET_ANSWER', answer);
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

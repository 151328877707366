<template>
  <TransitionBlowUp>
    <div
      class="BaseModal"
      :class="[`size--${size}`]"
      @click.stop="closeByX($event)"
    >
      <div class="modal fill-content">
        <div
          v-if="headerVisible"
          class="modal-header"
        >
          <h1 class="title">
            {{ title }}
          </h1>

          <BaseButton
            size="s"
            variation="tertiary"
            class="close-button"
            @click.native="closeByX($event)"
          >
            x
          </BaseButton>
        </div>

        <div class="modal-body">
          <slot />
        </div>
      </div>
    </div>
  </TransitionBlowUp>
</template>

<script>
import TransitionBlowUp from '@/transitions/TransitionBlowUp';

const sizes = ['s', 'm', 'l', 'xl', 'xxl', 'full'];

export default {
  components: {
    TransitionBlowUp,
  },

  props: {
    headerVisible: {
      type: Boolean,
      default: true,
    },

    size: {
      type: String,
      default: sizes[0],
      validator: size => sizes.includes(size),
    },

    title: {
      type: String,
      default: '',
    },
  },

  mounted() {
    const app = document.querySelector('#app');
    app.classList.add('hidden-scroll');
    document.removeEventListener('keydown', this.closeByEscape);
    document.addEventListener('keydown', this.closeByEscape);
  },

  methods: {
    closeByEscape(event) {
      if (event.key === 'Escape') {
        this.close();
      }
    },

    close() {
      this.isClosed = true;
      document.querySelector('#app').classList.remove('hidden-scroll');
      this.$emit('close');
    },

    closeByX(event) {
      const classList = event.target.classList.value.split(' ');

      if (classList.some(targetClass => ['BaseModal', 'close-button'].includes(targetClass))) {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.BaseModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    0,
    adjust-color($color-neutral-darkest, $alpha: -.5),
    adjust-color($color-primary-darkest, $alpha: -.5)
  );

  z-index: 20;
  padding: var(--padding);
  overflow-y: auto;

  display: grid;
  align-items: var(--local-align);
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
  backdrop-filter: blur(3px) grayscale(.6);

  .modal {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-areas: "header" "body";
    max-width: var(--max-width);
    width: 100%;
    margin: 0 auto;
    border-radius: var(--border-radius);
    background: #fff;
    box-shadow: $shadow-elevation-4;
    // max-height: 100%;
    max-height: 85vh;
    overflow-y: hidden;

    .modal-header {
      grid-area: header;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      padding: $spacing-xs;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 1px solid;
      // margin-bottom: $spacing-xs;

      .title{
        // color: #fff;
        // padding: $spacing-2xs $spacing-s;
        font-size: $font-size-xl;
        text-transform: uppercase;
        // font-weight: 400;
        font-weight: 500;
        font-size: $font-size-xl;
      }
    }

    .modal-body {
      grid-area: body;
      overflow-x: auto;
      background-color: $color-neutral-lightest;
    }
  }

  &.size {
    &--s {
      --padding: #{$spacing-m};
      --max-width: 600px;
      --border-radius: #{$border-radius-s};
      --local-align: start;
    }

    &--m {
      --padding: #{$spacing-2xl} #{$spacing-m};
      --max-width: 800px;
      --border-radius: #{$border-radius-m};
      --local-align: center;
    }

    &--l {
      --padding: #{$spacing-l} #{$spacing-m};
      --max-width: 1000px;
      --border-radius: #{$border-radius-l};
      --local-align: center;
    }

    &--xl {
      --padding: #{$spacing-3xl} #{$spacing-m};
      --max-width: 1200px;
      --border-radius: #{$border-radius-l};
      --local-align: stretch;
    }

    &--xxl {
      --padding: #{$spacing-m};
      --max-width: 1400px;
      --border-radius: #{$border-radius-l};
      --local-align: center;

      .modal{
        height: 100%;
      }
    }

    &--full {
      --padding: #{$spacing-2xs};
      --max-width: auto;
      --border-radius: #{$border-radius-m};
      --local-align: stretch;
    }
  }
}
</style>

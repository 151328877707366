import { render, staticRenderFns } from "./BaseFile.vue?vue&type=template&id=6532ffaa&scoped=true&"
import script from "./BaseFile.vue?vue&type=script&lang=js&"
export * from "./BaseFile.vue?vue&type=script&lang=js&"
import style0 from "./BaseFile.vue?vue&type=style&index=0&id=6532ffaa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6532ffaa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Guillermo\\Proyectos\\icc_front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6532ffaa')) {
      api.createRecord('6532ffaa', component.options)
    } else {
      api.reload('6532ffaa', component.options)
    }
    module.hot.accept("./BaseFile.vue?vue&type=template&id=6532ffaa&scoped=true&", function () {
      api.rerender('6532ffaa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/base/BaseFile.vue"
export default component.exports
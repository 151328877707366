<template>
  <div
    v-if="currentError"
    class="ValidationError"
  >
    <font-awesome-icon
      class="icon"
      :icon="['fas', 'exclamation']"
    />
    {{ currentError }}
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },

    errors: {
      type: Array,
      default: undefined,
    },
  },

  computed: {
    currentError() {
      if (!this.errors) return undefined;

      const currentError = this.errors.find(error => error.name === this.name);
      return currentError?.text;
    },
  },

  watch: {
    currentError(newValue) {
      if (!newValue) {
        const validationErrorDOM = this.$el.closest(`[name="${this.name}"].validation-error`);
        if (validationErrorDOM) {
          validationErrorDOM.classList.remove('validation-error');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ValidationError{
  font-size: $font-size-s;
  text-align: justify;
  padding: $spacing-xs;
  color: adjust-color($color-error, $lightness: -25%);
  background: transparent; //adjust-color($color-error, $alpha: -.3);
  // border-radius: $border-radius-s;
  // border-bottom: 1px solid;
  animation: move .5s;
  position: relative;
  transition: all .5s ease;
  left: 0;
  opacity: 1;
  margin-top: $spacing-2xs;
    animation: small-bounce .8s linear;

  .icon{
    margin-right: $spacing-2xs;
  }

  @keyframes move {
    0%{
      left: -$spacing-xl;
      opacity: 0;
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    { staticClass: "RadioChoicesCustom" },
    [
      _c("legend", [_vm._v(_vm._s(_vm.labelTag))]),
      _vm._l(_vm.choices, function(choice, i) {
        return _c("div", { key: i, staticClass: "choice-block" }, [
          _c(
            "div",
            { staticClass: "choice" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.valueOption,
                    expression: "valueOption"
                  }
                ],
                attrs: {
                  id: "" + _vm.labelTag + i,
                  name: _vm.labelTag,
                  type: "radio"
                },
                domProps: {
                  value: choice.value,
                  checked: _vm._q(_vm.valueOption, choice.value)
                },
                on: {
                  change: function($event) {
                    _vm.valueOption = choice.value
                  }
                }
              }),
              _c("label", { attrs: { for: "" + _vm.labelTag + i } }, [
                _c("span", [_vm._v(_vm._s(choice.text))])
              ]),
              choice.subquestion
                ? _c(
                    _vm.subquestionType(choice.subquestion),
                    _vm._b(
                      {
                        tag: "component",
                        staticClass: "subquestion",
                        on: {
                          input: function($event) {
                            _vm.answerSubquestion(
                              choice.value,
                              $event && $event.target
                                ? $event.target.value
                                : $event
                            )
                          }
                        },
                        nativeOn: {
                          click: function($event) {
                            _vm.valueOption = choice.value
                          }
                        },
                        model: {
                          value: choice.answer,
                          callback: function($$v) {
                            _vm.$set(choice, "answer", $$v)
                          },
                          expression: "choice.answer"
                        }
                      },
                      "component",
                      _vm.subquestionAttributes(
                        "sub-" + _vm.labelTag + i,
                        choice.subquestion
                      ),
                      false
                    )
                  )
                : _vm._e()
            ],
            1
          )
        ])
      }),
      _vm._t("error")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
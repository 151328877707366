var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "PieCard", class: ["size--" + _vm.size] },
    [
      _vm.colors
        ? _c("PieChart", {
            attrs: {
              values: _vm.values.map(function(ref) {
                var value = ref.value

                return value
              }),
              colors: _vm.colors,
              size: _vm.size
            }
          })
        : _vm._e(),
      _vm.colors
        ? _c(
            "div",
            { staticClass: "legend" },
            [
              _vm.title
                ? _c("h3", { staticClass: "title" }, [
                    _vm._v(" " + _vm._s(_vm.title) + " ")
                  ])
                : _vm._e(),
              _vm._l(_vm.values, function(ref, i) {
                var value = ref.value
                var text = ref.text
                return _c(
                  "p",
                  {
                    key: i,
                    staticClass: "value",
                    style: "color: " + _vm.colors[i]
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "circle",
                      attrs: { icon: "circle" }
                    }),
                    _c("span", [_vm._v(" " + _vm._s(text) + " ")]),
                    _c("span", [_vm._v(" " + _vm._s("(" + value + "%)") + " ")])
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e(),
      _c("font-awesome-icon", {
        staticClass: "icon fa-4x",
        attrs: { icon: _vm.iconData }
      }),
      _c("span", { staticClass: "scss" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';
import axios from 'axios';

const LOCAL_STORAGE_TOKEN = 'token';
const LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';

const getDefaultState = () => ({
  token: localStorage.getItem(LOCAL_STORAGE_TOKEN) || undefined,
  refreshToken: localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN) || undefined,
  user: undefined,
  loading: false,
});

const state = () => getDefaultState();

export const getters = {
  ...make.getters(state),

  isAuthenticated: state => !!state.token,

  isLoading: state => state.loading,

  getUser: state => state.user,
};

const mutations = {
  ...make.mutations(state),

  LOGIN(state, { token, refreshToken }) {
    state.token = token;
    localStorage.setItem(LOCAL_STORAGE_TOKEN, token);

    state.refreshToken = refreshToken;
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);

    axios.defaults.headers.Authorization = `Bearer ${token}`;
  },

  LOGOUT(state) {
    state.token = undefined;
    localStorage.removeItem(LOCAL_STORAGE_TOKEN);

    state.refreshToken = undefined;
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
  },

  SET_TOKEN(state, token) {
    state.token = token;
    localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
  },

  SET_REFERSH_TOKEN(state, refreshToken) {
    state.refreshToken = refreshToken;
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
  },
};

export const actions = {
  async login({ commit }, { username, password }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/login', { username, password });
      if (error) throw new Error(data);

      commit('LOGIN', { token: data.token, refreshToken: data.refresh_token });

      window.Vue.$router.push('/home');
    } catch (e) {
      commit('LOGOUT');
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async register({ commit }, registerData) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/register', registerData, {
        transformRequest: [(data, headers) => {
          // eslint-disable-next-line no-param-reassign
          delete headers.Authorization;
          return data;
        }, ...axios.defaults.transformRequest],
      });
      if (error) throw new Error(data);
    } catch (e) {
      commit('LOGOUT');
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async refreshToken({ state, commit }) {
    return axios.post('/token/refresh', { refresh_token: state.refreshToken }, {
      transformRequest: [(data, headers) => {
        // eslint-disable-next-line no-param-reassign
        delete headers.Authorization;
        return data;
      }, ...axios.defaults.transformRequest],
    })
      .then((response) => {
        try {
          const { token, refreshToken } = response.data;
          commit('LOGIN', { token, refreshToken });
        } catch (e) {
          commit('LOGOUT');
        }
      });
  },

  async passwordRecover({ commit }, { email }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/user/password/recover', { email }, {
        transformRequest: [(data, headers) => {
          // eslint-disable-next-line no-param-reassign
          delete headers.Authorization;
          return data;
        }, ...axios.defaults.transformRequest],
      });

      if (error) throw new Error(data);

      window.Vue.$toast.success(data);
      window.Vue.$router.push({ path: '/young/login' });
    } catch (e) {
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async passwordRecoverCheckCode({ commit }, code) {
    let validCode = false;

    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/user/password/check', { code }, {
        transformRequest: [(data, headers) => {
          // eslint-disable-next-line no-param-reassign
          delete headers.Authorization;
          return data;
        }, ...axios.defaults.transformRequest],
      });

      validCode = !error && data;
    } finally {
      commit('SET_LOADING', false);
    }

    return validCode;
  },

  async updatePassword({ commit }, { code, password }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/user/password/update', { code, password }, {
        transformRequest: [(data, headers) => {
          // eslint-disable-next-line no-param-reassign
          delete headers.Authorization;
          return data;
        }, ...axios.defaults.transformRequest],
      });

      if (error) throw new Error(data);

      window.Vue.$toast.success(data);
    } catch (e) {
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
      window.Vue.$router.push({ path: '/login' });
    }
  },

  async replacePassword({ commit }, { password, passwordNew }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.post('/user/password/replace', { password, passwordNew });
      if (error) throw new Error(data);

      window.Vue.$toast.success(data);
    } catch (e) {
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchUser({ commit }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get('/user/profile');
      if (error) throw new Error(data);

      commit('SET_USER', data);
    } catch (e) {
      window.Vue.$toast.error(e.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async logout({ commit }) {
    try {
      const { data, error } = await axios.get('/logout');
      if (error) throw new Error(data);
      window.Vue.$toast.success(data);
    // } catch (e) {
    //   console.log(e);
    } finally {
      commit('SET_USER', undefined);
      commit('LOGOUT');
      delete axios.defaults.headers.Authorization;

      if (!/login/g.test(window.Vue.$route.fullPath)) {
        window.Vue.$router.push({ name: 'Login' });
      }
    }
  },

  // async logoutByRefreshToken({ commit }) {
  //   return axios.get('/logout')
  //     .error(() => {
  //       commit('LOGOUT');
  //       delete axios.defaults.headers.Authorization;

  //       if (!/login/g.test(window.Vue.$route.fullPath)) {
  //         window.Vue.$router.push({ name: 'Home' });
  //       }
  //     });
  // },

  async testing() {
    try {
      const response = await axios.get('/test');
      // console.log(response);
      if (response.error) throw new Error(response.data);
    } catch (error) {
      // console.log('testing', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <main id="app">
    <TheHeader
      v-if="headerVisible"
      class="header"
    />

    <router-view class="page-content" />

    <TheFooter />
  </main>
</template>

<script>
import TheHeader from '@/components/header/TheHeader.vue';
import TheFooter from '@/components/footer/TheFooter.vue';

export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter,
  },

  computed: {
    headerVisible() {
      return this?.$route?.meta?.header ?? false;
    },
  },

};
</script>

<style lang="scss">
@import '~@/assets/styles/_reset.scss';
@import '~@/assets/styles/app.scss';

#app {
  display: flex;
  flex-flow: column;
  min-height: 100vh;

  font-family: $font-family-primary;
  background: $color-neutral-lightest;

  .header, .TheFooter{
    flex: 0 0 auto;
  }

  .page-content{
    flex: 1 1 100%;
  }

  &.hidden-scroll{
    overflow: hidden;
    max-height: 100vh;
  }
}
</style>

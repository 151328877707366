<template>
  <div class="HomeView">
    <div class="banner" />

    <div class="content fill-content">
      <TransitionSlide side="right">
        <div class="panel-description">
          <div
            class="description"
            v-html="$t(`HOME.DESCRIPTION`)"
          />
        </div>
      </TransitionSlide>

      <div
        v-if="modules.length"
        class="modules"
      >
        <Module
          v-for="(module, i) in modulesAttributes"
          :id="module.code"
          :key="module.code"
          :content="module"
          :autofocus="i === 0"
          @open="redirectToModuleContent(module.path)"
        />
      </div>

      <div
        v-else
        class="loading-modules"
      >
        <div
          v-for="i in 2"
          :key="i"
          class="loading-module"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import Module from '@/components/modules/Module.vue';
import TransitionSlide from '@/transitions/TransitionSlide';

const STORE = 'homeModule';

export default {
  components: {
    Module,
    TransitionSlide,
  },

  computed: {
    modules: get(`${STORE}/modules`),

    modulesAttributes() {
      const modules = [
        { path: '/evaluation', icons: ['tasks', 'chart-bar'] },
        { path: '/strategies', icons: ['chess', 'chess-knight'] },
        { path: '/cooperation', icons: ['user-friends', 'briefcase'] },
      ];

      return this.modules.map((module, i) => ({ ...module, ...modules[i] }));
    },
  },

  watch: {
    modules: {
      deep: true,
      handler() {
        this.focusFirstPhase();
      },
    },
  },

  async created() {
    await this.$store.dispatch(`${STORE}/fetchModules`);
  },

  methods: {
    redirectToModuleContent(path) {
      this.$router.push({ path });
    },

    focusFirstPhase() {
      const firstPhase = this.$el.querySelector('.Phase');
      if (firstPhase) firstPhase.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.HomeView{
  display: grid;
  grid-template-rows: 300px 1fr;

  .banner{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: inherit;
    background-position: center;
    display: grid;
    align-content: flex-end;
    background-image: url('~@/assets/images/home-banner.jpg');
  }


  .content{
    padding: 1rem;

    .panel-description{
      margin-left: auto;
      margin-top: -5rem;
      margin-bottom: $spacing-l;
      background: adjust-color($color-primary, $alpha: -.2);
      padding: $spacing-m;
      border-radius: 5px;
      color: #fff;
      backdrop-filter: blur(5px);
      max-width: 800px;

      .title{
        font-size: $font-size-l;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }

      .description{
        font-size: $font-size-m;

        ::v-deep strong{
          font-weight: 500;
        }
      }
    }

    .loading-modules,
    .modules{
      padding: $spacing-s 0;
      // display: grid;
      // grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      // grid-auto-rows: 250px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: $spacing-l $spacing-xl;
    }

    .loading-title{
      @include empty-content(50px);
      margin-bottom: $spacing-m;
    }
    .loading-description{
      @include empty-content(120px);
    }

    .loading-module{
      @include empty-content(100%);
    }
  }
}
</style>

<template>
  <div class="EvaluationView">
    <HeaderTemplate image="evaluation-header.jpg">
      <div v-html="$t('EVALUATION_HEADER')" />
    </HeaderTemplate>

    <div class="content fill-content">
      <QuestionnaireCard
        v-for="(questionnaire, j) in visibleQuestionnaires"
        :key="j"
        :content="{...questionnaire, complete: (questionnaire.complete && !evaluationClosed), isCurrent: isCurrentQuestionnaire(questionnaire.code)}"
        @click.native="openQuestionnaire(questionnaire)"
      />

      <component
        :is="finalCard"
        v-if="evaluationCardVisible"
        :available="allQuestionnairesCompleted"
        @click.native="openModal"
      />

      <BaseModal
        v-if="modalVisible"
        :header-visible="false"
        size="s"
        @close="closeModal"
      >
        <CloseEvaluationModalContent
          @close="closeModal"
          @close-evaluation="closeEvaluationModule"
        />
      </BaseModal>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import HeaderTemplate from '@/components/common/HeaderTemplate';
import QuestionnaireCard from '@/components/evaluation/QuestionnaireCard';
import ResultsCard from '@/components/evaluation/ResultsCard';
import CloseEvaluationCard from '@/components/evaluation/CloseEvaluationCard';
import CloseEvaluationModalContent from '@/components/evaluation/CloseEvaluationModalContent';

const STORE = 'questionnaireModule';

export default {
  components: {
    HeaderTemplate,
    QuestionnaireCard,
    ResultsCard,
    CloseEvaluationCard,
    CloseEvaluationModalContent,
  },

  data() {
    return {
      visibleQuestionnaires: [],
      evaluationCardVisible: false,
      modalVisible: false,
    };
  },

  computed: {
    questionnaires: get(`${STORE}/questionnaires`),
    evaluationClosed: get(`${STORE}/closed`),

    finalCard() {
      return this.evaluationClosed ? 'ResultsCard' : 'CloseEvaluationCard';
    },

    currentQuestionnaires() {
      return this.questionnaires.find(questionnaire => !questionnaire.complete);
    },

    allQuestionnairesCompleted() {
      return this.questionnaires?.every(questionnaire => questionnaire.complete) ?? false;
    },
  },

  async created() {
    await this.$store.dispatch(`${STORE}/fetchQuestionnaires`);

    this.makeQuestionnairesVisibles();
  },

  methods: {
    openModal() {
      if (this.evaluationClosed) {
        this.openResults();
      } else {
        this.modalVisible = true;
      }
    },

    closeModal() {
      this.modalVisible = false;
    },

    async closeEvaluationModule() {
      document.querySelector('#app').classList.remove('hidden-scroll');

      await this.$store.dispatch(`${STORE}/closeEvaluationModule`);
      this.closeModal();
    },

    openResults() {
      this.$router.push({ path: '/evaluation/results' });
    },

    makeQuestionnairesVisibles() {
      if ((this.questionnaires.length > 0) && (this.questionnaires.length !== this.visibleQuestionnaires.length)) {
        const questionnaire = this.questionnaires[this.visibleQuestionnaires.length];
        if (questionnaire) this.visibleQuestionnaires.push(questionnaire);

        setTimeout(this.makeQuestionnairesVisibles, 150);
      } else {
        this.evaluationCardVisible = true;
      }
    },
    openQuestionnaire(quesionnaire) {
      if (!this.isAvailable(quesionnaire)) return;

      this.$router.push({ path: `/form/${quesionnaire.code}` });
    },

    isCurrentQuestionnaire(quesionnaireCode) {
      return this.currentQuestionnaires?.code === quesionnaireCode;
    },

    isAvailable(quesionnaire) {
      const isCurrent = this.isCurrentQuestionnaire(quesionnaire.code);
      const isCompleted = quesionnaire.complete;

      return isCurrent || isCompleted;
    },
  },
};
</script>

<style lang="scss" scoped>
.EvaluationView{
  .content{
    padding: $spacing-l $spacing-s;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-auto-rows: 150px;
    gap: $spacing-s;
  }
}
</style>

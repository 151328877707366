<template>
  <div
    class="SmartProject"
    :class="{selected:selected}"
  >
    <p class="title">
      {{ $t(`SMART_PROJECT.KEY_FACTOR.${keyFactor}.STATE.${state}.TITLE`) }}
    </p>

    <p class="factor">
      {{ $t(`PILLAR.${pillar}.KEY_FACTOR.${keyFactor}.TITLE`) }}
    </p>

    <p class="pillar">
      {{ $t(`PILLAR.${pillar}.TITLE`) }}
    </p>

    <div class="stars">
      <font-awesome-icon
        v-for="star in 3"
        :key="star"
        :icon="[((star <= state) ? 'fas' : 'far'), 'star']"
      />
    </div>

    <span class="points">
      {{ points }}
      <font-awesome-icon icon="coins" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  computed: {
    pillar() {
      return this.content?.pillar;
    },

    keyFactor() {
      return this.content?.keyFactor;
    },

    state() {
      return this.content?.state;
    },

    selected() {
      return this.content?.selected;
    },

    points() {
      return this.content?.points;
    },
  },
};
</script>

<style lang="scss" scoped>
.SmartProject{
  box-shadow: $shadow-elevation-2;
  border-radius: $border-radius-m;
  background: #fff;
  overflow: hidden;
  padding: $spacing-m;
  position: relative;

  display: grid;
  align-content: center;
  justify-content: center;
  text-align: center;
  gap: $spacing-3xs;
  transition: all .3s ease;
  border: 2px solid transparent;

  .title{
    font-weight: 500;
  }

  .factor{
    color: $color-primary;
    text-transform: uppercase;

    font-size: $font-size-s;
  }

  .pillar{
    font-size: $font-size-s;
  }

  .stars{
    color: $color-secondary;
    position: absolute;
    right: $spacing-s;
    bottom: $spacing-2xs;
  }

  .points{
    position: absolute;
    left: $spacing-s;
    bottom: $spacing-2xs;
    font-size: $font-size-s;
    font-weight: 500;
    color: $color-primary-dark;
  }

  &.selected{
    border: 2px solid $color-primary;
    background: adjust-color($color: $color-primary, $lightness: 38%);
  }

  &:hover{
    cursor: pointer;
    border-color: $color-primary;
    transform: scale(1.05);
  }
}
</style>

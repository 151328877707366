<template>
  <div class="Statement">
    <component
      :is="statementType"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import Statement from '@/components/statements/SimpleStatement.vue';
import FileStatement from '@/components/statements/FileStatement.vue';

const STATEMENT_TYPES = {
  simple: 'Statement',
  file: 'FileStatement',
};

export default {
  components: {
    Statement,
    FileStatement,
  },

  computed: {
    statementType() {
      return (typeof STATEMENT_TYPES[this.$attrs.type] !== 'undefined') ?
        STATEMENT_TYPES[this.$attrs.type] :
        STATEMENT_TYPES.simple;
    },
  },

};
</script>

<style lang="scss" scoped>
.Statement {
  ::v-deep {
    h3 {
      font-weight: 600;
      margin-bottom: $spacing-s;
    }
    h3 {
      font-size: 1.5rem;
      margin: 1rem 0;
    }

    p {
      line-height: 1.5;
    }

    p:not(:last-child) {
      margin-bottom: $spacing-xs;
    }

    b {
      font-weight: 600;
    }
    strong {
      font-weight: 600;
      color: $color-primary;
    }

    ul {
      padding: $spacing-xs;
      padding-left: $spacing-xl;
      list-style: initial;

      li {
        margin-bottom: $spacing-xs;

        &::marker {
          color: $color-primary;
        }
      }
    }
    ol {
      margin-right: 5rem;
      padding: $spacing-s $spacing-2xl;
      list-style: initial;
      background: white;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      li {
        margin-bottom: $spacing-m;
        &::marker {
          content: counter(list-item) ') ';
          font-weight: bold;
          font-size: 1.05rem;
          color: $color-primary;
        }
      }
    }
  }
}
</style>

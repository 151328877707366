<template>
  <div class="MenuTooltipBody">
    <div class="image">
      <font-awesome-icon
        size="2x"
        :icon="['fas', 'user']"
      />
    </div>

    <div class="information">
      <p
        class="name"
        aria-label="Nombre completo de la empresa"
      >
        {{ company }}
      </p>

      <p
        class="email"
        aria-label="Email del usuario"
      >
        {{ email }}
      </p>

      <div class="points">
        <span>
          {{ points }}
        </span>

        <font-awesome-icon :icon="['fas', 'coins']" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  computed: {
    company() {
      return this.user.company ?? '--';
    },

    email() {
      return this.user.email ?? '--';
    },

    points() {
      return this.user.points ?? 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.MenuTooltipBody{
  display: grid;
  grid-template-columns: auto 1fr;
  padding: $spacing-m;
  gap: $spacing-m;

  .image{
    --local-image-size: 50px;
    width: var(--local-image-size);
    color: $color-primary-dark;
    background-color: #fff;

    display: grid;
    place-items: center;

  }

  .information{
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 0 $spacing-m;

    .name{
      font-size: $font-size-l;
      font-weight: 500;
      grid-column: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .email{
      font-size: $font-size-xs;
      color: $color-neutral-darker;
      grid-column: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .points{
      grid-column: 2;
      grid-row: 1/3;
      font-size: $font-size-xl;
      font-weight: 500;
      color: $color-secondary-dark;
    }
  }

  @media #{$breakpoint-sm-max} {
    padding: $spacing-m;

    .profile{
      --local-image-size: 50px;
    }
  }
}
</style>

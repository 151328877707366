<template>
  <div class="CooperationView">
    <HeaderTemplate>
      <div v-html="$t('COOPERATION_HEADER')" />
    </HeaderTemplate>

    <div class="content fill-content">
      <div class="users">
        <UserCard
          v-for="(user, j) in visibleUsers"
          :key="j"
          :user="user"
          @click.native="openUserCard(user)"
        />
      </div>

      <BaseButton
        class="cooperation-button"
        @click="modalVisible = true"
      >
        Quiero cooperar
      </BaseButton>

      <BaseModal
        v-if="modalVisible"
        :header-visible="false"
        size="s"
        @close="closeModal"
      >
        <CooperationModalContent @close="closeModal" />
      </BaseModal>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import HeaderTemplate from '@/components/common/HeaderTemplate';
import UserCard from '@/components/cooperation/UserCard';
import CooperationModalContent from '@/components/cooperation/CooperationModalContent';

const STORE = 'cooperationModule';

export default {
  components: {
    HeaderTemplate,
    UserCard,
    CooperationModalContent,
  },

  data() {
    return {
      visibleUsers: [],
      modalVisible: false,
    };
  },

  computed: {
    users: get(`${STORE}/users`),
  },

  async created() {
    await this.$store.dispatch(`${STORE}/fetchUsers`);

    this.makeUsersVisibles();
  },

  methods: {
    openModal() {
      if (this.evaluationClosed) {
        this.openResults();
      } else {
        this.modalVisible = true;
      }
    },

    closeModal() {
      this.modalVisible = false;
    },

    makeUsersVisibles() {
      if ((this.users.length > 0) && (this.users.length !== this.visibleUsers.length)) {
        const user = this.users[this.visibleUsers.length];
        if (user) this.visibleUsers.push(user);

        setTimeout(this.makeUsersVisibles, 150);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.CooperationView{
  .content{
    padding: $spacing-l $spacing-s;

    .users{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      margin-bottom: $spacing-xl;
      min-height: 400px;
      gap: $spacing-s;
    }

    .cooperation-button{
      margin: auto;
    }
  }
}
</style>

<template>
  <fieldset class="MultipleChoices">
    <legend>{{ labelTag }}</legend>

    <div
      v-for="(choice, i) in choices"
      :key="i"
      class="choice-block"
    >
      <div class="choice">
        <input
          :id="`${labelTag}${i}`"
          v-model="innerValue"
          :name="labelTag"
          type="checkbox"
          :value="choice.value"
        >

        <label :for="`${labelTag}${i}`">
          <span>{{ choice.text }}</span>
        </label>
      </div>
    </div>

    <slot name="error" />
  </fieldset>
</template>

<script>

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    innerValue: {
      get() {
        return this.value || [];
      },

      set(newValue) {
        this.$emit('input', newValue);
      },
    },

    choices() {
      return this.$attrs.choices.map(choice => ({ value: choice, text: this.getChoice(choice) ?? choice }));
    },

    labelTag() {
      return this.$attrs.name;
    },
  },

  methods: {
    getChoice(choice) {
      return this.$attrs.options[choice];
    },
  },
};
</script>


<style lang="scss" scoped>
.MultipleChoices {
  display: grid;
  gap: $spacing-2xs;

  legend{
    display: none;
  }

  .choice{
    label{
      padding: $spacing-2xs;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: $spacing-xs;

      &:hover{
        cursor: pointer;
        border-color: $color-primary;
        color:  $color-primary;

        &:before {
          background-color: $color-primary;
          box-shadow: inset 0 0 0 3px #fff;
        }
      }

      &:before {
        content: "";
        aspect-ratio: 1;
        background: #fff;
        border: 1px solid $color-neutral-mid-dark;
        display: inline-block;
        width: 1rem;
        height: 1rem;

        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }

    input {
      height: 0;
      width: 0;
      position: absolute;
      opacity: 0;

      &:checked + label {
        color: $color-primary;
        font-weight: 500;

        &:before {
          border-color: $color-primary;
          background-color: $color-primary;
          box-shadow: inset 0 0 0 2px #fff;
        }
      }

      &:focus + label{
        box-shadow: 0 0 0px 1px $color-primary;
      }
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TransitionBlowUp", [
    _c(
      "div",
      {
        staticClass: "BaseModal",
        class: ["size--" + _vm.size],
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.closeByX($event)
          }
        }
      },
      [
        _c("div", { staticClass: "modal fill-content" }, [
          _vm.headerVisible
            ? _c(
                "div",
                { staticClass: "modal-header" },
                [
                  _c("h1", { staticClass: "title" }, [
                    _vm._v(" " + _vm._s(_vm.title) + " ")
                  ]),
                  _c(
                    "BaseButton",
                    {
                      staticClass: "close-button",
                      attrs: { size: "s", variation: "tertiary" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.closeByX($event)
                        }
                      }
                    },
                    [_vm._v(" x ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "modal-body" }, [_vm._t("default")], 2)
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
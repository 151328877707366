/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';
import axios from 'axios';

const getDefaultState = () => ({
  loading: false,
  users: [],
});

const state = () => getDefaultState();

export const getters = {
  isLoading: state => state.loading,
};

export const mutations = {
  ...make.mutations(state),

};

export const actions = {
  async fetchUsers({ commit }) {
    try {
      commit('SET_LOADING', true);

      const { data, error } = await axios.get('/cooperation-users');
      if (error) throw new Error(data);

      commit('SET_USERS', data.users);
    } catch (e) {
      // console.log(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="PoliciesPanel">
    <h1 class="title">
      {{ $t('POLICY.TITLE') }}
    </h1>

    <div
      class="content"
      v-html="$t(`POLICY.CONTENT`)"
    />

    <BaseButton
      size="s"
      @click="$emit('accept-policies')"
    >
      {{ $t('BUTTON.ACCEPT') }}
    </BaseButton>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.PoliciesPanel{
  padding: $spacing-m;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title{
    font-size: $font-size-l;
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: 1px solid;
    align-self: stretch;
    padding: $spacing-m;
    margin-bottom: $spacing-m;;
  }

  .content{
    text-align: justify;
    margin-bottom: $spacing-l;
    font-size: $font-size-s;
    line-height: 1.8;
    padding: $spacing-m;

    ::v-deep{
      h2{
        margin-bottom: 1rem;
        font-size: $font-size-l;
        font-weight: 500;
      }

      label{
        font-weight: 500;
      }

      ul{
        padding-left: $spacing-xl;
        list-style-type: disc;
        margin-bottom: $spacing-s;
        li{
          margin-bottom: $spacing-2xs;
        }
      }

      b{
        font-weight: 500;
      }
      p{
        margin-bottom: $spacing-m;
      }
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BlockPagination" },
    [
      _c(
        "div",
        { staticClass: "pagination-button-pre" },
        [
          _vm.previous
            ? _c(
                "BaseButton",
                _vm._b(
                  {
                    staticClass: "previous-button",
                    attrs: { variation: "secondary" },
                    on: {
                      click: function($event) {
                        return _vm.changeBlock({
                          blockId: _vm.previous,
                          transitionSide: "left"
                        })
                      }
                    }
                  },
                  "BaseButton",
                  { title: "volver a la seccion anterior" },
                  false
                ),
                [
                  _c("font-awesome-icon", {
                    staticClass: "arrow",
                    attrs: { icon: ["fas", "chevron-left"] }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "pagination-content" }, [_vm._t("default")], 2),
      _c("PaginationBullets", {
        staticClass: "pagination-bullets",
        attrs: {
          bullets: _vm.blocks.length,
          current: _vm.currentBlock,
          theme: _vm.theme
        }
      }),
      _c(
        "div",
        { staticClass: "pagination-button-next" },
        [
          _vm.next
            ? _c(
                "BaseButton",
                _vm._b(
                  {
                    staticClass: "next-button",
                    on: {
                      click: function($event) {
                        return _vm.changeBlock({
                          blockId: _vm.next,
                          transitionSide: "right"
                        })
                      }
                    }
                  },
                  "BaseButton",
                  { title: "ir a la siguiente seccion" },
                  false
                ),
                [
                  _c("font-awesome-icon", {
                    staticClass: "arrow",
                    attrs: { icon: ["fas", "chevron-right"] }
                  })
                ],
                1
              )
            : _c(
                "BaseButton",
                _vm._b(
                  {
                    staticClass: "finish-button",
                    on: {
                      click: function($event) {
                        return _vm.submitBlock(_vm.next)
                      }
                    }
                  },
                  "BaseButton",
                  { title: "enviar formulario" },
                  false
                ),
                [
                  _c("font-awesome-icon", {
                    staticClass: "arrow",
                    attrs: { icon: ["fas", "paper-plane"] }
                  })
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <TransitionSlide side="bottom">
    <component
      :is="componentAccordingFocusability"
      class="Module"
      :class="{available: content.available}"
      v-on="(content.available) ? { click: open } : {}"
    >
      <div class="filter" />

      <p class="title">
        {{ $t(`MODULE.${content.code}.TITLE`) }}
      </p>
      <p class="subtitle">
        {{ $t(`MODULE.${content.code}.SUBTITLE`) }}
      </p>

      <font-awesome-icon
        class="icon-left"
        :icon="['fas', content.icons[0]]"
      />

      <font-awesome-icon
        class="icon-right"
        :icon="['fas', content.icons[1]]"
      />
    </component>
  </TransitionSlide>
</template>

<script>
import TransitionSlide from '@/transitions/TransitionSlide';

export default {
  components: {
    TransitionSlide,
  },

  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  computed: {
    componentAccordingFocusability() {
      return this.content.available ? 'button' : 'div';
    },
  },

  methods: {
    open() {
      this.$emit('open', this.content.key);
    },
  },
};
</script>

<style lang="scss" scoped>
.Module{
  box-shadow: $shadow-elevation-1;
  border-radius: $border-radius-m;
  border: none;
  padding: 0;
  background-color: #fff;
  background-size: cover;
  background-position: center;
  display: grid;
  align-content: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all .3s linear;
  max-width: 400px;
  width: 100%;
  box-shadow: $shadow-elevation-2;
  border: 2px solid transparent;
  min-height: 250px;

  $primary-transparent: transparent;// adjust-color($color-primary, $alpha: -.2);
  $secondary-transparent: transparent;// adjust-color($color-secondary, $alpha: -.2);

  .title{
    font-size: $font-size-l;
    font-weight: 500;
    text-transform: uppercase;
    // color: $color-neutral-dark;
    color: $color-neutral-dark;

    transition: all .3s ease;
  }

  [class*=icon] {
    position: absolute;
    bottom: -$spacing-m;
    font-size: 6rem;
    z-index: -1;
    transition: all .3s ease;
  }

  .icon-left{
    position: absolute;
    left: 0;
    transform: rotate(10deg);
    color: adjust-color($color-neutral-mid, $alpha: -.5);


  }
  .icon-right{
    position: absolute;
    right: 0;
    transform: rotate(-10deg);
    color: adjust-color($color-neutral-mid, $alpha: -.5);
  }

  .filter{
    position: absolute;
    box-shadow: $shadow-elevation-1, inset 120px -120px 100px $primary-transparent, inset -120px 120px 100px $secondary-transparent;
    opacity: 0;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    z-index: -1;
    transition: all .3s ease;
  }

  &:hover{
    cursor: pointer;
    border: 2px solid  $color-primary-light;

    .title{
      color: $color-primary-dark;
      letter-spacing: 2px;
      font-size: $font-size-xl;
    }

    .icon-left{
      font-size: 6.5rem;
      color: adjust-color($color-primary-lightest, $alpha: -.0);
      filter: drop-shadow(20px -20px 0px adjust-color($color-primary-lightest, $alpha: -.6));
    }
    .icon-right{
      font-size: 6.5rem;
      color: adjust-color($color-secondary-lightest, $alpha: -.0);
      filter: drop-shadow(-20px -20px 0px adjust-color($color-secondary-lightest, $alpha: -.6));
    }

    .filter{
      opacity: 1;
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    _vm._b(
      {
        staticClass: "BaseLink",
        class: [
          _vm.themeClass,
          "variation--" + _vm.variation,
          "size--" + _vm.size
        ]
      },
      "a",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
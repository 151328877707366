<template>
  <div
    class="PieCard"
    :class="[`size--${size}`]"
  >
    <PieChart
      v-if="colors"
      :values="values.map(({value}) => value)"
      :colors="colors"
      :size="size"
    />

    <div
      v-if="colors"
      class="legend"
    >
      <h3
        v-if="title"
        class="title"
      >
        {{ title }}
      </h3>

      <p
        v-for="({value, text}, i) in values"
        :key="i"
        class="value"
        :style="`color: ${colors[i]}`"
      >
        <font-awesome-icon
          icon="circle"
          class="circle"
        />
        <span> {{ text }} </span>
        <span> {{ `(${value}%)` }} </span>
      </p>
    </div>

    <font-awesome-icon
      :icon="iconData"
      class="icon fa-4x"
    />
    <span class="scss" />
  </div>
</template>

<script>
import PieChart from '@/components/results/PieChart';

export default {
  components: {
    PieChart,
  },

  props: {
    title: {
      type: String,
      default: undefined,
    },

    values: {
      type: Array,
      default: () => [{ value: 0, text: '' }],
    },

    size: {
      type: String,
      default: 'm',
    },

    icon: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      colors: undefined,
      data: undefined,
    };
  },

  computed: {
    iconData() {
      const icon = this?.icon;
      if (!icon) return '';

      const [type, ...name] = icon.split('-');
      return [type, name.join('-')];
    },
  },

  mounted() {
    this.loadScssValues();
  },

  methods: {
    loadScssValues() {
      const scssDOM = this.$el.querySelector('.scss');
      const scssDOMComputedStyles = window.getComputedStyle(scssDOM, null);
      const colorPrimary = scssDOMComputedStyles.getPropertyValue('color');
      const colorSecondary = scssDOMComputedStyles.getPropertyValue('background-color');

      this.colors = [colorPrimary, colorSecondary];
    },
  },
};
</script>

<style lang="scss" scoped>
.PieCard{
  display: grid;
  grid-template-columns: auto 1fr;
  padding: $spacing-xs;
  align-items: center;
  background: #fff;
  box-shadow: $shadow-elevation-1;
  border-radius: $border-radius-m;
  position: relative;
  overflow: hidden;

  &.size{
    &--m{
      --title-size: #{$font-size-xs};
      --leyend-size: #{$font-size-2xs};
    }

    &--l{
      --title-size: #{$font-size-l};
      --leyend-size: #{$font-size-s};
    }
  }


  .title{
    text-transform: uppercase;
    font-weight: 500;
    font-size: var(--title-size);
    margin-bottom: $spacing-2xs;
  }

  .legend{
    padding: $spacing-2xs $spacing-s;
    display: grid;
    font-size: var(--leyend-size);
    font-weight: 500;

    .circle{
      margin-right: $spacing-xs;
    }

    span{
      color: $color-neutral-darker;

      &:last-child{
        font-weight: 400;
      }
    }
  }

  .icon{
    position: absolute;
    font-size: 6rem;
    transform: rotate(-10deg);
    bottom: -1.25rem;
    right: .5rem;
    color: adjust-color($color-neutral-light, $alpha: -.6);
    z-index: -1rem;
  }

  .scss{
    display: none;
    color: $color-primary;
    background-color: $color-secondary;
  }
}
</style>

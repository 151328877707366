var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TransitionSlide", { attrs: { side: "bottom" } }, [
    _c(
      "div",
      { staticClass: "ResultsCard", class: [_vm.state] },
      [
        _c("div", { staticClass: "content" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("EVALUATION_CARD.RESULTS.TITLE")) + " ")
          ]),
          _c("p", { staticClass: "block" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("EVALUATION_CARD.RESULTS.DESCRIPTION")) + " "
            )
          ])
        ]),
        _c("font-awesome-icon", {
          staticClass: "icon fa-4x",
          attrs: { icon: ["far", "chart-bar"] }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
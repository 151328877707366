<template>
  <div
    class="PaginationBullets"
    :class="[themeClass]"
  >
    <div
      v-for="(bullet, i) in bullets"
      :key="bullet"
      class="bullet"
      :class="[{current: i === current},{completed: i < current}]"
    />
  </div>
</template>

<script>
export default {
  props: {
    bullets: {
      type: Number,
      default: 0,
    },

    current: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.PaginationBullets{
  --gap: #{$spacing-3xl};
  --size: #{$spacing-m};

  display: grid;
  justify-content: center;
  gap: var(--gap);
  grid-auto-flow: column;
  grid-auto-columns: $spacing-s;

  &.theme--{
    &light{
      --background: #{$color-neutral-mid-darker};
    }

    &dark{
      --background: #fff;
    }
  }

  .bullet{
    height: var(--size);
    width: var(--size);
    border-radius: 50px;
    transition: all .3s ease;
    position: relative;
    transform: scale(.25);

    &:not(:last-child):after{
      content: "";
      background: #fff;
      position: absolute;
      left: 100%;
      right: 0;
      top: calc(50% - 1px);
      height: 2px;
      transition: all .3s ease;
    }

    &.current{
      transform: scale(1);
      background: $color-primary;
      box-shadow: inset 0 0 1px 2px #fff,  0 0 5px 2px $color-primary-light;
    }

    &.completed{
      transform: scale(1);
      background: $color-primary-light;
      &:after{
        background: $color-primary-light;
        right: calc(var(--gap) * -1);
      }
    }

    &:not(.current):not(.completed){
      background: var(--background);
    }

  }

  @media #{$breakpoint-lg-max}{
    --gap: #{$spacing-l};
    --size: #{$spacing-s};
  }
}
</style>

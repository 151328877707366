var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CooperationModalContent" },
    [
      _c("font-awesome-icon", {
        staticClass: "icon",
        attrs: { icon: ["fas", "info-circle"] }
      }),
      _c("div", {
        staticClass: "content",
        domProps: { innerHTML: _vm._s(_vm.$t("COOPERATION_MODAL.CONTENT")) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "TheHeader" }, [
    _c(
      "header",
      [
        _c(
          "router-link",
          { attrs: { to: "/home", "aria-label": "Ir a la página principal" } },
          [
            _c("img", {
              staticClass: "logo",
              attrs: {
                src: require("@/assets/images/logo.svg"),
                alt: "Logo Coca-Cola"
              }
            })
          ]
        ),
        _c("MenuTooltip", { attrs: { user: _vm.user } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }